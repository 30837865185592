import { Component, HostBinding } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { NgxMaskDirective } from 'ngx-mask';

import { InputProps } from '../form.types';

@Component({
  selector: 'rkt-form-input',
  template: `
    <input
      [type]="type"
      [name]="fieldName"
      [formControl]="formControl"
      class="rkt-form-input"
      [autocomplete]="autocomplete"
      [formlyAttributes]="field"
      [class.is-invalid]="showError"
      [mask]="props.maskString || ''"
      [showMaskTyped]="true"
    />
  `,
  standalone: true,
  imports: [ReactiveFormsModule, NgxMaskDirective, FormlyModule],
})
export class RktFormInputComponent extends FieldType<FieldTypeConfig<InputProps>> {
  @HostBinding('class.rkt-form-input-field') commonClass = true;

  get type() {
    return this.props.type || 'text';
  }

  get autocomplete() {
    return this.props.autocomplete || 'off';
  }

  get fieldName() {
    return this.field.name ?? (this.props.label?.toLowerCase().replace(/\s/g, '-') || '');
  }
}
