import { NgClass, NgIf } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { NgxMaskDirective } from 'ngx-mask';

import { RktIconComponent } from '../../icon';
import { InputProps } from '../form.types';

const visaPrefix = /^4/;
const mastercardPrefix = /^(?:2[2-7]|5[1-5])/;
const amexPrefix = /^3[47]/;
const discoverPrefix = /^6011\d{12}|65\d{14}$/;

@Component({
  selector: 'rkt-form-input-card',
  template: `
    <div class="rkt-form-input-card-icon">
      <rkt-icon name="card" *ngIf="!cardType"></rkt-icon>
      <div *ngIf="cardType" class="card-icon" [ngClass]="cardType"></div>
    </div>
    <input
      type="text"
      [name]="fieldName"
      [formControl]="formControl"
      class="rkt-form-input rkt-form-input-card"
      [formlyAttributes]="field"
      [class.is-invalid]="showError"
      [autocomplete]="autocomplete"
      [mask]="props.maskString || 'XXXX XXXX XXXX XXXX'"
      (ngModelChange)="getCardType($event)"
    />
  `,
  standalone: true,
  imports: [NgIf, RktIconComponent, NgClass, ReactiveFormsModule, NgxMaskDirective, FormlyModule],
})
export class RktFormInputCardComponent extends FieldType<FieldTypeConfig<InputProps>> {
  @HostBinding('class.rkt-form-input-field') commonClass = true;

  get autocomplete() {
    return this.props.autocomplete || 'off';
  }

  get fieldName() {
    return this.field.name ?? (this.props.label?.toLowerCase().replace(/\s/g, '-') || '');
  }

  cardType?: string;

  getCardType(cardNumber?: string) {
    if (!cardNumber) {
      this.cardType = undefined;
      return;
    }

    if (visaPrefix.test(cardNumber)) {
      this.cardType = 'visa';
    } else if (mastercardPrefix.test(cardNumber)) {
      this.cardType = 'mastercard';
    } else if (amexPrefix.test(cardNumber)) {
      this.cardType = 'amex';
    } else if (discoverPrefix.test(cardNumber)) {
      this.cardType = 'discover';
    } else {
      this.cardType = undefined;
    }
  }
}
