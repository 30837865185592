import { AbstractControl, ValidationErrors } from '@angular/forms';

export const cardNumberValidator = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }

  if (/[^\s\-0-9]+/.test(control.value)) {
    return { isValidCardNumber: { message: 'Invalid input entered.' } };
  }

  // Luhn Algorithm
  let nCheck = 0;
  let bEven = false;
  let value = control.value;

  value = value.replace(/\D/g, '');

  for (let n = value.length - 1; n >= 0; n--) {
    const cDigit = value.charAt(n);
    let nDigit = parseInt(cDigit, 10);

    if (bEven && (nDigit *= 2) > 9) {
      nDigit -= 9;
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0 ? null : { isValidCardNumber: { message: 'Card number invalid.' } };
};
