import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map } from 'rxjs/operators';

import { ErrorModalComponent } from '@rocketcoms/components';
import { ModalService } from '@rocketcoms/rocket-ui';

import { ModalsActions } from './modals.actions';

@Injectable()
export class ModalsEffects {
  constructor(
    private actions$: Actions,
    private modalsService: ModalService,
  ) {}

  openErrorDialog$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ModalsActions.openErrorDialog),
        map(({ text, title }) => {
          const modalRef = this.modalsService.open(ErrorModalComponent);
          modalRef.componentInstance.modalTitle = title;
          modalRef.componentInstance.errorMessage = text;
        }),
      );
    },
    { dispatch: false },
  );

  dismissAll$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ModalsActions.dismissAll),
        map(() => this.modalsService.dismissAll()),
      );
    },
    { dispatch: false },
  );
}
