import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ColumnMode, DatatableComponent, NgxDatatableModule, SelectionType, SortType } from '@swimlane/ngx-datatable';
import { intersectionWith, isEqual, uniq, uniqueId, xorBy } from 'lodash-es';
import { NgxPaginationModule } from 'ngx-pagination';

import { DatatableColumn, DatatableSorting, FetchData } from '@rocketcoms/interfaces';

import { RktIconComponent } from '../icon';
import { RktTableBodyCellComponent } from './body-cell/body-cell.component';
import { RktTableHeaderCellComponent } from './header-cell/header-cell.component';
import { RktTablePagerComponent } from './pager/pager.component';
import { SplitPipe } from './pipes';
import { RktTablePlaceholderCellComponent } from './placeholder-cell/placeholder-cell.component';

@Component({
  selector: 'rkt-table',
  templateUrl: './table.component.html',
  standalone: true,
  imports: [
    NgClass,
    NgFor,
    NgIf,
    NgStyle,
    SplitPipe,
    RktIconComponent,
    NgxPaginationModule,
    NgxDatatableModule,
    RktTablePlaceholderCellComponent,
    RktTablePagerComponent,
    RktTableBodyCellComponent,
    RktTableHeaderCellComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RktTableComponent<T> implements OnInit {
  @ViewChild('datatable') tableRef!: DatatableComponent;
  @Input() rows: T[] = [];
  @Input() columns: DatatableColumn[] = [];
  @Input() selected: T[] = [];
  @Input() limit = 25; // Page size to show.
  @Input() count = 0; // Total count of all rows
  @Input() offset = 0; // Current offset ( page - 1 ) shown
  @Input() paginationMaxSize = 7; // maximum number of page links to display
  @Input() externalPaging = true;
  @Input() externalSorting = true;
  @Input() loadingIndicator = false;
  @Input() scrollbarH = false;
  @Input() scrollbarV = false;
  @Input() reorderable = false;
  @Input() sortType: SortType = SortType.single;
  @Input() messages = { emptyMessage: 'No data to display' };
  @Input() selectionType?: SelectionType;
  @Input() columnMode: ColumnMode = ColumnMode.flex;
  @Input() headerHeight = 39;
  @Input() footerHeight = 80;
  @Input() rowHeight = 44;
  @Input() stickyHeader = true;
  @Input() dateTimeFormat = 'MMM dd, yyyy hh:mm aaa';
  @Input() dateFormat = 'MMM dd, yyyy';
  @Input() timeFormat = 'hh:mm aaa';
  @Input() amountFormat = '1.2-2';
  @Input() placeholderItemsCount = 10;
  @Input() isClickable = true;
  @Input() isInteractive = true;
  @Input() isItemsAlignCenter = false;
  @Input() sortParam?: DatatableSorting;
  @Input() emptyValuePlaceholder?: string;
  @Input() tableId = uniqueId('rkt-table-');
  @Input() rowIdentityField = 'id';
  @Input() paginationLabel?: string;
  @Input() detailsTemplate?: TemplateRef<ElementRef>;
  @Input() detailsRowHeight: number = '100%' as unknown as number;
  @Output() fetchData: EventEmitter<FetchData> = new EventEmitter<FetchData>();
  @Output() rowActivated: EventEmitter<T> = new EventEmitter<T>();
  @Output() rowSelected: EventEmitter<T> = new EventEmitter<T>();
  @Output() selectAllClicked = new EventEmitter<boolean>();
  @Output() colButtonClicked: EventEmitter<{ colButton: string; row: T }> = new EventEmitter<{ colButton: string; row: T }>();
  @Output() switchButtonToggled: EventEmitter<unknown> = new EventEmitter<unknown>();

  getId({ id }: Record<string, string>) {
    return id;
  }

  get isCheckboxSelection(): boolean {
    return this.selectionType === SelectionType.checkbox;
  }

  get isShowPlaceholder() {
    return this.loadingIndicator && !this.rows?.length;
  }

  get footerAndPlaceholderHeight() {
    const footerHeight = this.footerHeight;
    if (!this.isShowPlaceholder) {
      return footerHeight;
    }

    return this.placeholderItemsCount * this.rowHeight + footerHeight;
  }

  get allVisibleRowsSelected() {
    if (!this.rows.length || !this.selected.length) {
      return false;
    }

    const alreadySelected = intersectionWith(this.notDisabledRows, this.selected, isEqual);

    return this.notDisabledRows.length === alreadySelected.length;
  }

  get anyVisibleRowsSelected() {
    if (!this.rows.length || !this.selected.length) {
      return false;
    }

    const alreadySelected = intersectionWith(this.notDisabledRows, this.selected, isEqual);

    return alreadySelected.length > 0;
  }

  get notDisabledRows() {
    return this.rows.filter((item) => {
      const { isDisabled } = item as { isDisabled?: boolean };
      return !isDisabled;
    });
  }

  constructor(public ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.fetchData.emit({
      page: this.offset,
      size: this.limit,
      sortParams: this.externalSorting ? this.sortParam : null,
    });
  }

  setPage({ offset }: { offset: number }): void {
    this.offset = offset - 1;

    if (this.externalPaging) {
      this.fetch();
    }
  }

  onSort({ column, sorts }: { column: DatatableColumn; sorts: { prop: string; dir: 'asc' | 'desc' }[] }): void {
    if (sorts?.length) {
      const sortProp = `${this.columns.find((col) => col.prop === column.prop)?.sortProp ?? column.prop}`;
      const sortDir = this.sortParam?.key === sortProp ? sorts[0].dir || 'desc' : 'desc';
      this.sortParam = { key: sortProp, sortProp: `${column.prop}`, sortDir };
    }

    this.fetch();
  }

  onActivate(activateEvent: { type: string; row: T; cellIndex: number; event: Event }): void {
    if (!activateEvent) {
      return;
    }

    if (this.isCheckboxSelection && activateEvent.cellIndex === 0) {
      activateEvent.event.stopPropagation();
      return;
    }

    if (!!this.detailsTemplate && activateEvent.cellIndex === 0) {
      activateEvent.event.stopPropagation();
      return;
    }

    if (activateEvent.type === 'click') {
      this.rowActivated.emit(activateEvent.row);
    }
  }

  onSelect(row: T): void {
    this.rowSelected.emit(row);
  }

  checkSelectable({ isDisabled }: { isDisabled?: boolean }): boolean {
    return !isDisabled;
  }

  getRowClass({ isDisabled, rowClassName }: { isDisabled?: boolean; rowClassName?: string }): Record<string, boolean | undefined> {
    return {
      ...(rowClassName && { [`${rowClassName}`]: true }),
      'datatable-body-row-disabled': isDisabled,
    };
  }

  onButtonClick(colButton: string, row: T): void {
    this.colButtonClicked.emit({ colButton, row });
  }

  onSwitchButtonToggled(data: any = {}, row: T): void {
    this.switchButtonToggled.emit({ event: data.event, cell: data.cell, row });
  }

  selectAll() {
    if (!this.allVisibleRowsSelected) {
      this.selected = uniq([...this.selected, ...this.notDisabledRows]);
    } else {
      this.selected = xorBy(this.selected, this.notDisabledRows, 'id');
    }

    this.rowSelected.emit({ selected: [...this.selected] } as unknown as T);
  }

  toggleExpandRow(row: T) {
    this.tableRef?.rowDetail.toggleExpandRow(row);
  }
}
