import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, filter, fromEvent, takeUntil } from 'rxjs';

import { FormModel } from '@rocketcoms/interfaces';
import { ActiveModal, RktButtonDirective, RktFormFieldConfig, RktFormModule, formInput, formRow } from '@rocketcoms/rocket-ui';

import { AttentionModalComponent } from '../attention-modal/attention-modal.component';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['confirm-modal.component.scss'],
  standalone: true,
  imports: [AttentionModalComponent, RktButtonDirective, RktFormModule],
})
export class ConfirmModalComponent implements AfterViewChecked, OnDestroy {
  confirmType: 'warning' | 'error' | 'success' = 'error';

  title = 'Attention';

  text!: string;

  confirmBtnText?: string;

  confirmForm = new FormGroup({});

  formModel: FormModel<any> = {};

  formFields: RktFormFieldConfig[] = [
    formRow([
      formInput({
        key: 'name',
        label: 'Name',
        props: { maxLength: 255, required: true },
        expressions: {
          'props.label': () => this.nameConfirmation?.label ?? 'Name',
        },
        validation: {
          messages: {
            required: ' ',
          },
        },
      }),
    ]),
  ];

  nameConfirmation?: { label: string; value: string };

  ref = inject(ChangeDetectorRef);

  private destroy$ = new Subject<void>();

  constructor(public activeModal: ActiveModal) {
    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(
        filter((e: KeyboardEvent) => {
          return e.key === 'Enter';
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: () =>
          requestAnimationFrame(() => {
            this.activeModal.close(true);
          }),
      });
  }

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
