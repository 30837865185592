import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';

import { ApiService } from '@rocketcoms/data-access';
import { ChangePasswordProps, LoginResponse, ResetPasswordProps, TokenInfo } from '@rocketcoms/interfaces';

interface RefreshTokenResponse {
  token: string;
  refreshToken: string;
  expiry: number;
}

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    protected http: HttpClient,
    private apiService: ApiService,
  ) {}

  // API: User login request
  login(email: string, password: string): Observable<LoginResponse> {
    return this.apiService.post<LoginResponse>('/auth/login', {
      email,
      password,
    });
  }

  // API: Refresh JWT token
  refresh(token: string): Observable<TokenInfo> {
    return this.apiService.post<RefreshTokenResponse>('/auth/refresh', { token }).pipe(
      map((response) => ({
        expiry: response.expiry,
        refreshToken: response.refreshToken,
        token: response.token,
        tokenType: 'Bearer',
      })),
    );
  }

  // API: User logout request
  logout(): Observable<boolean> {
    return this.apiService.post<void>('/auth/logout').pipe(
      map(() => true),
      catchError(() => of(true)),
    );
  }

  // API: Send email with reset link to user's email inbox
  forgotPassword(email: string): Observable<void> {
    return this.apiService.post<void>(`/auth/reset/${email}`, { email });
  }

  // API: Change user password
  changePassword(changePasswordData: ChangePasswordProps): Observable<void> {
    return this.apiService.patch<void>(`/auth/password`, changePasswordData);
  }

  // API: User confirmation request
  registrationConfirmation(token: string): Observable<void> {
    return this.apiService.get<void>(`/auth/confirmation/${token}`);
  }

  // API: Reset password with temporary token
  resetPassword(resetPasswordData: ResetPasswordProps): Observable<void> {
    return this.apiService.put<void>(`/auth/reset`, resetPasswordData);
  }
}
