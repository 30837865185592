import { Pipe, PipeTransform } from '@angular/core';
import { format, parse } from 'date-fns';

import { isValidTableDate } from './date-format.pipe';

@Pipe({
  name: 'rktTimeFormat',
  standalone: true,
})
export class TimeFormatPipe implements PipeTransform {
  transform(time: string, timeFormat: string): string {
    const timeObj = time ? parse(time, 'HH:mm:ss', new Date()) : undefined;
    if (timeObj && isValidTableDate(timeObj)) {
      return format(new Date(timeObj), timeFormat);
    }
    return '';
  }
}
