import { CommonModule, DecimalPipe, NgClass, NgIf, NgStyle, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { isNil } from 'lodash-es';

import { DatatableColumn } from '@rocketcoms/interfaces';
import { TooltipDirective } from '@rocketcoms/utils';

import { RktIconComponent } from '../../icon';
import { InputSwitchComponent } from '../input-switch/input-switch.component';
import { DateFormatPipe } from '../pipes/date-format.pipe';
import { ShortIdPipe } from '../pipes/short-id.pipe';
import { SplitPipe } from '../pipes/split-pipe';
import { TimeFormatPipe } from '../pipes/time-format.pipe';

@Component({
  selector: 'rkt-table-body-cell',
  templateUrl: './body-cell.component.html',
  standalone: true,
  imports: [
    CommonModule,
    NgClass,
    NgStyle,
    NgIf,
    RktIconComponent,
    UpperCasePipe,
    DecimalPipe,
    TitleCasePipe,
    DateFormatPipe,
    TimeFormatPipe,
    SplitPipe,
    ShortIdPipe,
    InputSwitchComponent,
    FormsModule,
    TooltipDirective,
  ],
})
export class RktTableBodyCellComponent {
  @Input() column!: DatatableColumn;

  @Input() rowHeight!: number;

  @Input() dateTimeFormat!: string;

  @Input() dateFormat!: string;

  @Input() timeFormat!: string;

  @Input() amountFormat!: string;

  @Input() currencyFormat!: string;

  @Input() value = '';

  @Input() rowIndex!: number;

  @Input() emptyValuePlaceholder?: string;

  @Output() colBtnClicked = new EventEmitter<string>();

  @Output() switchButtonToggled: EventEmitter<unknown> = new EventEmitter<unknown>();

  get isEmptyValue(): boolean {
    return isNil(this.value) || this.value === '';
  }

  formatStatusTag(text: string): string {
    return text?.replace(/_/g, ' ');
  }

  onBtnClick(event: MouseEvent, value: string) {
    event.stopPropagation();
    this.colBtnClicked.emit(value);
  }

  onSwitchToggle(event: unknown, cell: unknown): void {
    this.switchButtonToggled.emit({ event, cell });
  }
}
