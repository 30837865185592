import { Component, Directive, ElementRef, HostBinding, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { format, parse } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

import { DateFnsInputDate } from '@rocketcoms/interfaces';

import { RktIconComponent } from '../../icon';
import { DatepickerProps } from '../form.types';


const formatDateToObj = (date: number | Date) => {
  const dateFormatted = format(date, 'dd MM yyyy').split(' ');

  return NgbDate.from({
    day: parseInt(dateFormatted[0], 10),
    month: parseInt(dateFormatted[1], 10),
    year: parseInt(dateFormatted[2], 10),
  });
};

export const isValidDate = (date: DateFnsInputDate): date is Date => !(date === undefined || date === null || !date);

@Directive({
  selector: '[dateInput]',
  standalone: true,
})
export class DateInputDirective implements OnInit, OnChanges {
  @Input() dateInput = '';
  @Input() set dateFormat(dateFormat: string | undefined) {
    this._format = dateFormat || 'MM/dd/yy';
  }

  get dateFormat(): string {
    return this._format;
  }

  private _format = 'MM/dd/yy';

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.elementRef.nativeElement.value = this.transform(this.dateInput, this.dateFormat);
  }

  ngOnChanges(): void {
    this.elementRef.nativeElement.value = this.transform(this.dateInput, this.dateFormat);
  }

  transform(date: DateFnsInputDate, dateFormat: string): string {
    if (date && isValidDate(new Date(date))) {
      return format(new Date(date), dateFormat);
    }
    return '';
  }
}

@Component({
  selector: 'rkt-form-input',
  template: `
    <input
      ngbDatepicker
      #datePicker="ngbDatepicker"
      name="datepicker"
      class="rkt-form-input hidden"
      [(ngModel)]="datePickerModel"
      (dateSelect)="onDateSelect($event)"
      (closed)="onDatepickerClose()"
      [minDate]="minDate!"
      [maxDate]="maxDate!"
      [displayMonths]="1"
      [dayTemplate]="t"
      tabindex="-1"
      [autoClose]="true"
      [markDisabled]="isDisabled"
    />

    <ng-template #t let-date let-focused="focused" let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled">
      <span
        class="custom-day"
        [class.selected]="selected"
        [class.disabled]="disabled"
        [class.focused]="focused"
        [title]="date.day"
        [class.text-muted]="date.month !== currentMonth"
      >
        {{ date.day }}
      </span>
    </ng-template>
    <input
      [dateInput]="formControl.value"
      class="rkt-form-input date-selector"
      #datePickerInputRef
      [formControl]="formControl"
      [placeholder]="props.placeholder"
      [readOnly]="true"
      (click)="datePicker.open()"
    />

    <button class="calendar-toggle-button" type="button" title="Select Date">
      <rkt-icon name="calendar"></rkt-icon>
    </button>
  `,
  standalone: true,
  imports: [NgbDatepickerModule, ReactiveFormsModule, FormsModule, DateInputDirective, RktIconComponent],
})
export class RktFormDatepickerComponent extends FieldType<FieldTypeConfig<DatepickerProps>> implements OnInit {
  @ViewChild('datePickerInputRef')
  datePickerInputRef!: ElementRef<HTMLInputElement>;

  @HostBinding('class.rkt-form-input-field') commonClass = true;

  @HostBinding('class.rkt-form-datepicker-field') datepickerClass = true;

  get minDate() {
    return this.props.minDate || formatDateToObj(toZonedTime(new Date().setFullYear(new Date().getFullYear() - 120), 'America/Toronto'));
  }

  get maxDate() {
    return this.props.maxDate || formatDateToObj(toZonedTime(new Date().setDate(new Date().getDate() - 1), 'America/Toronto'));
  }

  datePickerModel: NgbDateStruct | null = null;

  get fieldName() {
    return this.field.name ?? (this.props.label?.toLowerCase().replace(/\s/g, '-') || '');
  }

  constructor(
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.formControl?.value) {
      const date = parse(this.formControl.value, 'yyyy-MM-dd', new Date());
      const formattedDate = format(date, 'MM/dd/yyyy');

      this.formControl.setValue(formattedDate);

      this.datePickerModel = formatDateToObj(date);
    }
  }

  onDateSelect(date: NgbDate): void {
    if (!date) {
      return;
    }

    const selectedDate = this.formatter.format(date);
    const formattedDate = format(parse(selectedDate, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy');

    this.formControl.setValue(formattedDate);
  }

  onDatepickerClose() {
    setTimeout(() => {
      this.datePickerInputRef?.nativeElement.blur();
    }, 0);
  }

  isDisabled = (date: NgbDateStruct) => {
    const isDateDisabled = !!this.props.disabledDates?.find((x) => new NgbDate(x.year, x.month, x.day).equals(date));

    const isWeekdayDisabled = !!this.props.disabledWeekdays?.includes(
      this.calendar?.getWeekday(new NgbDate(date.year, date.month, date.day)),
    );

    return isDateDisabled || isWeekdayDisabled;
  };
}
