import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { NgxPaginationModule, PaginationControlsComponent } from 'ngx-pagination';

import { RktIconComponent } from '../../icon';

@Component({
  selector: 'rkt-table-pager',
  templateUrl: './pager.component.html',
  standalone: true,
  imports: [NgxPaginationModule, NgIf, RktIconComponent, NgFor, NgClass],
})
export class RktTablePagerComponent extends PaginationControlsComponent {
  @HostBinding('class.rkt-table-pager') pager = true;

  @Input() limit!: number;

  @Input() paginationLabel?: string;
}
