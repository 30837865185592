import { NgIf } from '@angular/common';
import { Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { NgxMaskDirective } from 'ngx-mask';

import { InputProps } from '../form.types';

@Component({
  selector: 'rkt-form-input',
  template: `
    <div class="input-secured-value" (click)="onSecuredValueClick()" *ngIf="getIsSecureValueDisplayed()">
      {{ securedValuePrefix + initValue }}
    </div>
    <input
      #inputRef
      type="text"
      [name]="fieldName"
      [formControl]="formControl"
      (focus)="onInputFocus()"
      (blur)="onInputBlur()"
      class="rkt-form-input"
      [autocomplete]="autocomplete"
      [formlyAttributes]="field"
      [class.is-invalid]="showError"
      [mask]="props.maskString || ''"
    />
  `,
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, NgxMaskDirective, FormlyModule],
})
export class RktFormInputSecureComponent extends FieldType<FieldTypeConfig<InputProps>> implements OnInit, OnDestroy {
  @HostBinding('class.rkt-form-input-field') commonClass = true;

  @ViewChild('inputRef') inputRef?: ElementRef;

  initValue?: string;

  isInitValueVisible = false;

  get securedValuePrefix() {
    return this.props.securedValuePrefix || '';
  }

  get type() {
    return this.props.type || 'text';
  }

  get autocomplete() {
    return this.props.autocomplete || 'off';
  }

  get fieldName() {
    return this.field.name ?? (this.props.label?.toLowerCase().replace(/\s/g, '-') || '');
  }

  ngOnInit() {
    this.initValue = this.formControl.value;

    if (this.initValue) {
      this.formControl.disable();
      this.isInitValueVisible = true;
    } else {
      this.formControl.enable();
      this.isInitValueVisible = false;
    }
  }

  ngOnDestroy(): void {
    this.initValue = undefined;
    this.isInitValueVisible = true;

    if (this.field?.formControl) {
      this.formControl.enable();
    }
  }

  onSecuredValueClick() {
    this.formControl.enable();
    this.inputRef?.nativeElement.focus();
  }

  onInputFocus() {
    this.isInitValueVisible = false;
    const currentValue = this.formControl.value?.replace(/-/gu, '');
    if (currentValue === this.initValue) {
      this.formControl.patchValue('');
    }
    this.formControl.enable();
  }

  onInputBlur() {
    this.isInitValueVisible = true;
    const currentValue = this.formControl.value?.replace(/-/gu, '');
    if (!currentValue || currentValue === this.initValue) {
      this.formControl.patchValue(this.initValue);
      this.formControl.disable();
      this.formControl.markAsUntouched();
    }
  }

  getIsSecureValueDisplayed() {
    const currentValue = this.formControl.value?.replace(/-/gu, '');
    return this.isInitValueVisible && this.initValue && currentValue === this.initValue;
  }
}
