import { AsyncPipe, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';

import { TooltipDirective } from '@rocketcoms/utils';

import { RktIconComponent } from '../../icon';
import { RadioGroupProps } from '../form.types';
import { RktFormTooltipComponent } from '../tooltip/tooltip.component';

@Component({
  selector: 'rkt-form-radio-group',
  template: `
    <div *ngFor="let option of props.options | formlySelectOptions: field | async; let i = index" class="rkt-form-radio">
      <label class="rkt-form-radio-label" [for]="id + '_' + i" [class.disabled]="option.disabled || formControl.disabled">
        <input
          hidden
          type="radio"
          [id]="id + '_' + i"
          class="rkt-form-radio-input"
          [name]="field.name || id"
          [class.is-invalid]="showError"
          [attr.value]="option.value"
          [value]="option.value"
          [formControl]="formControl"
          [formlyAttributes]="field"
          [attr.disabled]="option.disabled || formControl.disabled ? true : null"
        />
        <div class="rkt-form-radio-icon"></div>

        <div class="rkt-form-radio-value">
          {{ option.label }}
        </div>

        @if (props.optionTooltips?.[option.value]) {
          <rkt-icon class="default-tooltip-icon" name="info" [rktTooltip]="props.optionTooltips?.[option.value]!"></rkt-icon>
        }
      </label>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    ReactiveFormsModule,
    RktIconComponent,
    TooltipDirective,
    FormlyModule,
    RktFormTooltipComponent,
    AsyncPipe,
    FormlySelectModule,
  ],
})
export class RktFormRadioGroupComponent extends FieldType<FieldTypeConfig<RadioGroupProps>> {
  @HostBinding('class.rkt-form-radio-group-field') commonClass = true;
}
