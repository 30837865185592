import { DOCUMENT, NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { isString } from 'lodash-es';
import { Subject, fromEvent } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { DraggableDirective } from './draggable.directive';

export enum ModalDismissReasons {
  backdropClick,
  escPress,
}

@Component({
  selector: 'rkt-modal',
  styleUrls: ['./modal.component.scss'],
  templateUrl: './modal.component.html',
  standalone: true,
  imports: [NgClass, DraggableDirective],
})
export class ModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('ModalDialog', { read: ElementRef, static: false }) elementRef!: ElementRef;

  @ViewChild('dragHandle') dragHandle!: ElementRef;

  @Input() keyboard = true;

  @Input() isDraggable = false;

  @Input() backdrop = false;

  @Input() size: 'sm' | 'lg' | 'md' | 'full-size' | 'half-size' = 'lg';

  @Input() className!: string;

  @Output() dismissEvent = new EventEmitter();

  public sizeClasses!: string;

  private element!: Element;

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    this.setClasses();
  }

  ngAfterViewInit() {
    if (this.backdrop) {
      this.element = this.elementRef.nativeElement;
      this.element.addEventListener('click', (el) => {
        const className = (el.target as Element).className;
        if (isString(className) && className.split(' ').includes('app-modal')) {
          this.dismissEvent.emit(ModalDismissReasons.backdropClick);
        }
      });
    }

    fromEvent<KeyboardEvent>(this.document, 'keydown')
      .pipe(
        takeUntil(this.destroy$),
        filter((e: KeyboardEvent) => e.key === 'Escape' && this.keyboard),
      )
      .subscribe((e: KeyboardEvent) =>
        requestAnimationFrame(() => {
          if (!e.defaultPrevented) {
            this.ngZone.run(() => this.dismissEvent.emit(ModalDismissReasons.escPress));
          }
        }),
      );
  }

  ngOnDestroy() {
    if (this.element) {
      this.element.remove();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  setClasses() {
    if (this.size) {
      this.sizeClasses = `modal-${this.size}`;
    } else {
      this.sizeClasses = 'modal-md';
    }
  }
}
