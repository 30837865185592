import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';

import { SelectProps } from '../form.types';

@Component({
  selector: 'rkt-form-select',
  template: `
    <ng-select
      #selectRef
      class="rkt-form-select"
      [class.is-invalid]="showError"
      [items]="to.options | formlySelectOptions: field | async"
      [placeholder]="to.placeholder || ''"
      [virtualScroll]="props.virtualScroll ?? false"
      [tabIndex]="to.tabindex || 0"
      [formControl]="formControl"
      [formlyAttributes]="field"
      (close)="selectRef.blur()"
      [bindLabel]="labelProp"
      [bindValue]="valueProp"
      [clearable]="props.clearable ?? props.searchable ?? false"
      [searchable]="props.searchable ?? false"
      [selectOnTab]="true"
      appendTo="body"
    ></ng-select>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgSelectModule, ReactiveFormsModule, FormlyModule, AsyncPipe, FormlySelectModule],
})
export class RktFormSelectComponent extends FieldType<FieldTypeConfig<SelectProps>> {
  @HostBinding('class.rkt-form-select-field') commonClass = true;

  get labelProp(): string {
    return this.props.labelProp || 'label';
  }

  get valueProp(): string {
    return this.props.valueProp || 'value';
  }
}
