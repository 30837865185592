import { AbstractControl, ValidationErrors } from '@angular/forms';

export const stateCodeValidator = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }

  const regex = /^[0-9a-z]+$/i;

  const valid = regex.test(control.value);

  return valid ? null : { isValidStateCode: { message: 'Please select a valid state code.' } };
};
