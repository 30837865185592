import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { NotificationService } from '@rocketcoms/data-access';

import { MessagesActions } from './messages.actions';

@Injectable()
export class MessagesEffects {
  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
  ) {}

  errorNotification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MessagesActions.displayError),
        map((notification) => this.notificationService.error({ message: notification.message })),
      );
    },
    { dispatch: false },
  );

  successNotification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MessagesActions.displaySuccess),
        map((notification) => this.notificationService.success({ message: notification.message })),
      );
    },
    { dispatch: false },
  );

  warningNotification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MessagesActions.displayWarning),
        map((notification) => this.notificationService.warning({ message: notification.message })),
      );
    },
    { dispatch: false },
  );

  clearAllNotifications$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MessagesActions.clearAll),
        map(() => this.notificationService.clearAll()),
      );
    },
    { dispatch: false },
  );
}
