import { NgFor, NgIf } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';

import { RktIconComponent } from '../../icon';

@Component({
  selector: 'rkt-form-repeat',
  template: `
    <div *ngFor="let field of field.fieldGroup; let i = index" class="rkt-form-repeat-item">
      <button type="button" *ngIf="i === 0" (click)="add()" class="repeat-item-btn" [disabled]="isAddDisabled">
        <rkt-icon name="plus" class="add-icon"></rkt-icon> {{ addText }}
      </button>

      <button type="button" *ngIf="i !== 0" (click)="remove(i)" class="repeat-item-btn">
        <rkt-icon name="trash" class="remove-icon"></rkt-icon>{{ removeText }}
      </button>

      <formly-field [field]="field"></formly-field>
    </div>
  `,
  standalone: true,
  imports: [NgFor, NgIf, RktIconComponent, FormlyModule],
})
export class RktFormRepeatComponent extends FieldArrayType {
  @HostBinding('class.rkt-form-repeat') commonClass = true;

  get addText() {
    return this.props['addText'] || 'Add';
  }

  get removeText() {
    return this.props['removeText'] || 'Remove';
  }

  get isAddDisabled() {
    const maxItems = this.props['maxItems'] || 20;

    return this.field.fieldGroup!.length >= maxItems;
  }
}
