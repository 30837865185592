import { InjectionToken } from '@angular/core';
import * as fromRouter from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import CryptoJS from 'crypto-es';
import { localStorageSync } from 'ngrx-store-localstorage';

export const ENCRYPTION_KEY = 'key-key'; // generate key

const encryptFunction = (str?: string): string => {
  if (!str) {
    return '';
  }

  try {
    return CryptoJS.AES.encrypt(str, ENCRYPTION_KEY).toString();
  } catch (_e) {
    return '';
  }
};
const decryptFunction = (str?: string): string => {
  if (!str) {
    return '';
  }

  try {
    return CryptoJS.AES.decrypt(str, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
  } catch (_e) {
    return '';
  }
};

export interface RootState {
  router: fromRouter.RouterReducerState<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<RootState, Action>>('Root reducers token', {
  factory: () => ({
    router: fromRouter.routerReducer,
  }),
});

// sync auth data with localstorage
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const localStorageSyncMetaReducer = <T, V extends Action = Action>(reducer: ActionReducer<any>): ActionReducer<T, V> => {
  return (state, action) => {
    return localStorageSync({
      keys: [
        {
          auth: {
            encrypt: encryptFunction,
            decrypt: decryptFunction,
          },
        },
      ],
      rehydrate: true,
      removeOnUndefined: true,
      restoreDates: false,
      storage: window.localStorage,
      storageKeySerializer: (key) => `rcoms_ops_${key}`,
    })(reducer)(state, action);
  };
};

export const metaReducers: MetaReducer<RootState>[] = [localStorageSyncMetaReducer];

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectRouteDataParam, // factory function to select a route data param
  selectUrl, // select the current url
  selectTitle, // select the title if available
} = fromRouter.getRouterSelectors();
