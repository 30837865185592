import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

import { Notification } from '@rocketcoms/interfaces';

@Injectable({
  providedIn: 'root',
})
export class NgxToastrService {
  constructor(private toastr: ToastrService) {}

  individualConfig: Partial<IndividualConfig> = {
    positionClass: 'toast-top-right',
    closeButton: true,
    enableHtml: false,
    timeOut: 5000,
    extendedTimeOut: 2000,
    disableTimeOut: false,
    newestOnTop: true,
    progressBar: false,
    tapToDismiss: false,
  };

  show(notification: Notification, type: string, messageConfig?: Partial<IndividualConfig>): void {
    this.toastr.show(notification.message, '', { ...this.individualConfig, ...messageConfig }, type);
  }

  clear(): void {
    this.toastr.clear();
  }
}
