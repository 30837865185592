import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { ApiService } from '@rocketcoms/data-access';
import {
  BusinessAccount,
  BusinessAccountList,
  BusinessAccountListResponse,
  BusinessAccountResponse,
  PageParamsRequestProps,
} from '@rocketcoms/interfaces';
import { toPhone } from '@rocketcoms/utils';

@Injectable({
  providedIn: 'root',
})
export class BusinessAccountService {
  constructor(private apiService: ApiService) {}

  public getBusinessAccounts({ page, size, sortParams }: PageParamsRequestProps): Observable<BusinessAccountList> {
    const pageSize = size ?? 15;

    let params = new HttpParams().set('page', `${page}`).set('pageSize', `${pageSize}`);

    if (sortParams) {
      params = params.append('sortBy', sortParams.key);
      params = params.append('sortDir', sortParams.sortDir);
    }

    return this.apiService
      .get<BusinessAccountListResponse>(`/admin/accounts`, {
        params,
      })
      .pipe(
        map((response) => this.mapBusinessAccountListData(response)),
        shareReplay(),
      );
  }

  public getBusinessAccountById(id: string): Observable<BusinessAccount> {
    return this.apiService.get<BusinessAccountResponse>(`/admin/accounts/${id}`).pipe(
      map((response) => response.result),
      shareReplay(),
    );
  }

  public getAllCampaigns({ page }: PageParamsRequestProps): Observable<any> {
    const pageSize = 15;

    const params = new HttpParams().set('page', `${page ?? 0}`).set('pageSize', `${pageSize}`);

    return this.apiService
      .get<any>(`/campaigns`, {
        params,
      })
      .pipe(
        map((response) => this.mapCampaignListData(response)),
        shareReplay(),
      );
  }

  private mapCampaignListData(response: any): any {
    const { content, total } = response.result;

    const items = content.map((item: any) => ({
      ...item,
      campaignId: item.id,
    }));

    return {
      items,
      totalElements: total,
    };
  }

  public addBusinessAccount(businessAccount: any): Observable<BusinessAccount> {
    return this.apiService.post<BusinessAccount>(`/admin/accounts`, businessAccount).pipe(map((response) => response));
  }

  private mapBusinessAccountListData(response: BusinessAccountListResponse): BusinessAccountList {
    const { content, total } = response.result;

    const items = content.map((item) => ({
      ...item,
      businessAccountId: item.id,
      phoneNumber: toPhone(item.phoneNumber),
      status: item.isActive ? 'Active' : 'Disabled',
      baDetailsBtn: 'baDetailsBtn',
    }));

    return {
      items,
      totalElements: total,
    };
  }

  public getRoles(): any {
    return this.apiService.get<BusinessAccount>(`/roles`).pipe(map((response) => response));
  }

  loginToBusinessAccount(id: string) {
    return this.apiService.post<any>(`/admin/accounts/${id}/login`);
  }
}
