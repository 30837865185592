import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';

import { HelperBlock } from './modal-draggable-helper-block';
import { IPosition, Position } from './modal-position';

interface OutOfBounds {
  top: boolean;
  right: boolean;
  bottom: boolean;
  left: boolean;
}

@Directive({
  selector: '[rktModalDraggable]',
  exportAs: 'rktModalDraggable',
  standalone: true,
})
export class DraggableDirective implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() handle?: HTMLElement;

  @Input() bounds?: HTMLElement;

  @Input() set rktModalDraggable(setting: boolean) {
    if (!setting) {
      this.allowDrag = !!setting;

      const element = this.getDragEl();

      if (this.allowDrag) {
        this.renderer.addClass(element, 'rkt-modal-draggable');
      } else {
        this.putBack();
        this.renderer.removeClass(element, 'rkt-modal-draggable');
      }
    }
  }

  outOfBounds: OutOfBounds = {
    top: false,
    right: false,
    bottom: false,
    left: false,
  };

  position: IPosition = { x: 0, y: 0 };

  edge = new EventEmitter<OutOfBounds>();

  allowDrag = true;

  moving = false;

  original!: Position | null;

  oldTrans: Position | null = new Position(0, 0);

  tempTrans: Position | null = new Position(0, 0);

  currTrans: Position | null = new Position(0, 0);

  needTransform = false;

  draggingSub!: Subscription | null;

  helperBlock!: HelperBlock | null;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {
    this.helperBlock = new HelperBlock(el.nativeElement, renderer);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.boundsCheck();
  }

  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  onMouseDown(event: MouseEvent | TouchEvent): void {
    if (event instanceof MouseEvent && event.button === 2) {
      return;
    }

    const target = event.target;
    if (this.handle !== undefined && target && !this.checkHandleTarget(target, this.handle)) {
      return;
    }

    if (this.allowDrag === false) {
      return;
    }

    this.original = Position.fromEvent(event, this.getDragEl());
    this.pickUp();
  }

  ngOnInit(): void {
    if (this.allowDrag) {
      const element = this.getDragEl();
      this.renderer.addClass(element, 'rkt-modal-draggable');
    }
    this.resetPosition();
  }

  ngOnDestroy(): void {
    this.bounds = undefined;
    this.handle = undefined;
    this.original = null;
    this.oldTrans = null;
    this.tempTrans = null;
    this.currTrans = null;
    this.helperBlock?.dispose();
    this.helperBlock = null;

    if (this.draggingSub) {
      this.draggingSub.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['position'] && !changes['position'].isFirstChange()) {
      const p = changes['position'].currentValue;

      if (!this.moving) {
        if (Position.isIPosition(p)) {
          this.oldTrans?.set(p);
        } else {
          this.oldTrans?.reset();
        }

        this.transform();
      } else {
        this.needTransform = true;
      }
    }
  }

  ngAfterViewInit(): void {
    this.boundsCheck();
    if (this.tempTrans && this.oldTrans) {
      this.oldTrans.add(this.tempTrans);
      this.tempTrans.reset();
    }
  }

  resetPosition(): void {
    if (Position.isIPosition(this.position)) {
      this.oldTrans?.set(this.position);
    } else {
      this.oldTrans?.reset();
    }
    this.tempTrans?.reset();
    this.transform();
  }

  boundsCheck() {
    if (!this.bounds) {
      return this.outOfBounds;
    }

    const boundary = this.bounds.getBoundingClientRect();
    const el = this.el.nativeElement.getBoundingClientRect();
    const result = {
      top: this.outOfBounds.top ? true : boundary.top < el.top,
      right: this.outOfBounds.right ? true : boundary.right > el.right,
      bottom: this.outOfBounds.bottom ? true : boundary.bottom > el.bottom,
      left: this.outOfBounds.left ? true : boundary.left < el.left,
    };

    if (!this.tempTrans) {
      this.transform();
      return result;
    }

    if (!result.top) {
      this.tempTrans.y -= el.top - boundary.top;
    }

    if (!result.bottom) {
      this.tempTrans.y -= el.bottom - boundary.bottom;
    }

    if (!result.right) {
      this.tempTrans.x -= el.right - boundary.right;
    }

    if (!result.left) {
      this.tempTrans.x -= el.left - boundary.left;
    }

    this.transform();

    return result;
  }

  checkHandleTarget(target: EventTarget, element: Element) {
    if (element.tagName === 'BUTTON') {
      return false;
    }

    if (element === target) {
      return true;
    }

    for (const child in element.children) {
      if (Object.hasOwn(element.children, child)) {
        if (this.checkHandleTarget(target, element.children[child])) {
          return true;
        }
      }
    }

    return false;
  }

  onMouseMove(event: MouseEvent | TouchEvent): void {
    if (this.moving && this.allowDrag) {
      this.helperBlock?.add();
      this.moveTo(Position.fromEvent(event, this.getDragEl()));
    }
  }

  private getDragEl(): HTMLElement | ElementRef {
    return this.handle ? this.handle : this.el.nativeElement;
  }

  private moveTo(p: Position | null) {
    if (!p) {
      return;
    }

    if (this.original) {
      p.subtract(this.original);
      this.tempTrans?.set(p);

      this.transform();

      if (this.bounds) {
        this.edge.emit(this.boundsCheck());
      }
    }
  }

  private transform(): void {
    if (!this.tempTrans || !this.oldTrans || !this.currTrans) {
      return;
    }
    const translateX = this.tempTrans.x + this.oldTrans.x;
    const translateY = this.tempTrans.y + this.oldTrans.y;

    const value = `translate(${Math.round(translateX)}px, ${Math.round(translateY)}px)`;

    this.renderer.setStyle(this.el.nativeElement, 'transform', value);
    this.renderer.setStyle(this.el.nativeElement, '-webkit-transform', value);
    this.renderer.setStyle(this.el.nativeElement, '-ms-transform', value);
    this.renderer.setStyle(this.el.nativeElement, '-moz-transform', value);
    this.renderer.setStyle(this.el.nativeElement, '-o-transform', value);

    this.currTrans.x = translateX;
    this.currTrans.y = translateY;
  }

  private pickUp(): void {
    if (!this.moving) {
      this.moving = true;

      const element = this.getDragEl();
      this.renderer.addClass(element, 'modal-dragging');

      this.subscribeEvents();
    }
  }

  private subscribeEvents(): void {
    this.draggingSub = fromEvent(document, 'mousemove', { passive: false }).subscribe((event) => this.onMouseMove(event as MouseEvent));
    this.draggingSub.add(fromEvent(document, 'touchmove', { passive: false }).subscribe((event) => this.onMouseMove(event as TouchEvent)));
    this.draggingSub.add(fromEvent(document, 'mouseup', { passive: false }).subscribe(() => this.putBack()));

    const isIEOrEdge = /msie\s|trident\//i.test(window.navigator.userAgent);
    if (!isIEOrEdge) {
      this.draggingSub.add(fromEvent(document, 'mouseleave', { passive: false }).subscribe(() => this.putBack()));
    }
    this.draggingSub.add(fromEvent(document, 'touchend', { passive: false }).subscribe(() => this.putBack()));
    this.draggingSub.add(fromEvent(document, 'touchcancel', { passive: false }).subscribe(() => this.putBack()));
  }

  private unsubscribeEvents(): void {
    this.draggingSub?.unsubscribe();
    this.draggingSub = null;
  }

  private putBack() {
    if (this.moving) {
      this.helperBlock?.remove();

      if (this.needTransform) {
        if (Position.isIPosition(this.position)) {
          this.oldTrans?.set(this.position);
        } else {
          this.oldTrans?.reset();
        }

        this.transform();
        this.needTransform = false;
      }

      if (this.bounds) {
        this.edge.emit(this.boundsCheck());
      }

      this.moving = false;

      if (this.tempTrans && this.oldTrans) {
        this.oldTrans.add(this.tempTrans);
        this.tempTrans.reset();
      }

      const element = this.getDragEl();
      this.renderer.removeClass(element, 'modal-dragging');

      this.unsubscribeEvents();
    }
  }
}
