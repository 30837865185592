import { ComponentRef } from '@angular/core';

import { ContentRef } from './modal-content-ref.class';
import { ModalComponent } from './modal.component';

export interface CanDeactivateComponent {
  canDeactivate?: () => boolean;
}

export class ActiveModal {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  close(_result?: any): void {
    // ActiveModal close
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dismiss(_reason?: any): void {
    // ActiveModal dismiss
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
export class ModalRef<TComponent = any, TResult = any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: Promise<TResult | undefined>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private resolve!: (result?: any) => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private reject!: (reason?: any) => void;

  private body: HTMLBodyElement | null;

  constructor(
    private windowComponentRef: ComponentRef<ModalComponent> | null,
    private contentRef: ContentRef | null,
    private beforeDismiss?: () => boolean | Promise<boolean>,
  ) {
    if (this.windowComponentRef) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.windowComponentRef.instance.dismissEvent.subscribe((reason: any) => this.dismiss(reason));
    }

    this.result = new Promise((resolve, reject) => ([this.resolve, this.reject] = [resolve, reject]));
    this.result.then(null, () => null);
    this.body = document.querySelector('body');
  }

  get componentInstance(): TComponent {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    return this.contentRef?.componentRef?.instance!;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  close(result?: any): void {
    if (!this.windowComponentRef) {
      return;
    }

    this.resolve(result);
    this.removeModalElements();
    this.enableBodyScroll();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dismiss(reason?: any): void {
    if (!this.windowComponentRef) {
      return;
    }

    if (!this.beforeDismiss) {
      this.doDismiss(reason);
    } else {
      const dismiss = this.beforeDismiss();
      if (dismiss && dismiss instanceof Promise) {
        dismiss.then((result) => (result !== false ? this.doDismiss(reason) : false));
      } else if (dismiss !== false) {
        this.doDismiss(reason);
      }
    }
  }

  private enableBodyScroll(): void {
    if (!this.body) {
      return;
    }
    this.body.classList.remove('no-scroll');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private doDismiss(reason: any): void {
    const instance = this.componentInstance as CanDeactivateComponent | undefined;

    // If ESC button pressed and modal has canDeactivate, check it
    if (reason === 1 && instance?.canDeactivate && !instance.canDeactivate()) {
      return;
    }

    this.enableBodyScroll();
    this.reject(reason);
    this.removeModalElements();
  }
  // private doDismiss(reason: any): void {
  //   // If ESC button pressed and modal can't be deactivated
  //   if (reason === 1 && this.componentInstance?.canDeactivate && !this.componentInstance?.canDeactivate()) {
  //     return;
  //   }

  //   this.enableBodyScroll();
  //   this.reject(reason);
  //   this.removeModalElements();
  // }

  private removeModalElements(): void {
    if (!this.windowComponentRef) {
      return;
    }
    const windowNativeEl = this.windowComponentRef.location.nativeElement;
    windowNativeEl.parentNode.removeChild(windowNativeEl);
    this.windowComponentRef.destroy();

    if (this.contentRef && this.contentRef.viewRef) {
      this.contentRef.viewRef.destroy();
    }

    [this.windowComponentRef, this.contentRef] = [null, null];
  }
}
