import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';

import { RktIconComponent } from '@rocketcoms/rocket-ui';

export enum Criteria {
  at_least_eight_chars,
  at_least_one_lower_case_char,
  at_least_one_upper_case_char,
  at_least_one_digit_char,
  at_least_one_special_char,
}

export interface StrengthChange {
  strengthString: string;
  isAllChecksPass: boolean;
}

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
  standalone: true,
  imports: [RktIconComponent],
})
export class PasswordStrengthComponent implements OnChanges {
  @Input() password!: string;

  @Input() isModalForm = false;

  @Output() strengthChanged: EventEmitter<StrengthChange> = new EventEmitter<StrengthChange>();

  validators: Criteria[] = Object.values(Criteria) as Criteria[];

  criteriaMap = new Map<Criteria, RegExp>();

  containAtLeastEightChars = false;

  containAtLeastOneLowerCaseLetter = false;

  containAtLeastOneUpperCaseLetter = false;

  containAtLeastOneDigit = false;

  containAtLeastOneSpecialChar = false;

  passwordFormControl: AbstractControl;

  private strength = 0;

  constructor() {
    this.criteriaMap.set(Criteria.at_least_eight_chars, RegExp(/^.{8,63}$/));
    this.criteriaMap.set(Criteria.at_least_one_lower_case_char, RegExp(/^(?=.*?[a-z])/));
    this.criteriaMap.set(Criteria.at_least_one_upper_case_char, RegExp(/^(?=.*?[A-Z])/));
    this.criteriaMap.set(Criteria.at_least_one_digit_char, RegExp(/^(?=.*?\d)/));
    this.criteriaMap.set(Criteria.at_least_one_special_char, RegExp(/^(?=.*?[ !"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~])/));

    this.passwordFormControl = new UntypedFormControl('', [
      ...this.validators.map((criteria) => Validators.pattern(this.criteriaMap.get(criteria) ?? '')),
    ]);
  }

  ngOnChanges(): void {
    if (this.password && this.password.length > 0) {
      this.calculatePasswordStrength();
    } else {
      this.reset();
    }
  }

  calculatePasswordStrength(): void {
    const requirements: boolean[] = [];
    const unit = 100 / this.criteriaMap.size;

    requirements.push(
      this.isContainAtLeastEightChars(),
      this.isContainAtLeastOneLowerCaseLetter(),
      this.isContainAtLeastOneUpperCaseLetter(),
      this.isContainAtLeastOneSpecialChar(),
      this.isContainAtLeastOneDigit(),
    );

    const passedRequirements = requirements.filter((v) => v).length;
    this.strength = passedRequirements * unit;
    this.strengthChanged.emit({ strengthString: this.strengthString, isAllChecksPass: passedRequirements === requirements.length });
  }

  reset() {
    this.strength = 0;
    this.containAtLeastEightChars = false;
    this.containAtLeastOneLowerCaseLetter = false;
    this.containAtLeastOneUpperCaseLetter = false;
    this.containAtLeastOneDigit = false;
    this.containAtLeastOneSpecialChar = false;

    this.calculatePasswordStrength();
  }

  get strengthString(): string {
    if (this.strength > 0 && this.strength <= 40) {
      return 'Poor';
    } else if (this.strength > 40 && this.strength <= 80) {
      return 'Average';
    } else if (this.strength > 80) {
      return 'Good';
    }
    return '';
  }

  private isContainAtLeastEightChars(): boolean {
    this.containAtLeastEightChars = this.password ? this.password.length >= 8 : false;
    return this.containAtLeastEightChars;
  }

  private isContainAtLeastOneLowerCaseLetter(): boolean {
    this.containAtLeastOneLowerCaseLetter = !!this.criteriaMap.get(Criteria.at_least_one_lower_case_char)?.test(this.password);
    return this.containAtLeastOneLowerCaseLetter;
  }

  private isContainAtLeastOneUpperCaseLetter(): boolean {
    this.containAtLeastOneUpperCaseLetter = !!this.criteriaMap.get(Criteria.at_least_one_upper_case_char)?.test(this.password);
    return this.containAtLeastOneUpperCaseLetter;
  }

  private isContainAtLeastOneDigit(): boolean {
    this.containAtLeastOneDigit = !!this.criteriaMap.get(Criteria.at_least_one_digit_char)?.test(this.password);
    return this.containAtLeastOneDigit;
  }

  private isContainAtLeastOneSpecialChar(): boolean {
    this.containAtLeastOneSpecialChar = !!this.criteriaMap.get(Criteria.at_least_one_special_char)?.test(this.password);
    return this.containAtLeastOneSpecialChar;
  }
}
