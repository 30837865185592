import { KeyValuePipe, NgClass, NgFor } from '@angular/common';
import { Component, Directive, ElementRef, EventEmitter, HostBinding, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { type CountryCode, parsePhoneNumberWithError } from 'libphonenumber-js';
import { head } from 'lodash-es';

import { InputPhoneCountry, InputPhoneProps } from '../form.types';
import { countries, detectCountryFromPhoneNumber, filterCountryListByCode, getCountryDetails } from '../utils';

@Directive({
  selector: '[clickOutside]',
  standalone: true,
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<MouseEvent>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }
}

@Component({
  selector: 'rkt-form-input-phone',
  template: `
    <div class="input-phone-dropdown" (clickOutside)="hideDropdown()">
      <button type="button" class="input-phone-dropdown-toggle" [class.opened]="isOpened" (click)="toggleDropdown()">
        <div class="input-phone-dropdown-flag fi" [ngClass]="'fi-' + selectedCountry.code.toLowerCase()"></div>
        <div class="input-phone-dropdown-arrow"></div>
      </button>
      <div class="input-phone-dropdown-menu">
        <ul class="input-phone-country-list">
          <li class="input-phone-country-list-item" *ngFor="let country of countryList | keyvalue" (click)="onCountrySelect(country.value)">
            <div class="input-phone-dropdown-flag fi" [ngClass]="'fi-' + country.key.toLowerCase()"></div>
            <span class="input-phone-dropdown-country-name">{{ country.value.name }}</span>
            <span class="input-phone-dropdown-dial-code">+{{ country.value.dialCode }}</span>
          </li>
        </ul>
      </div>
    </div>

    <input
      #inputRef
      class="rkt-form-input"
      (change)="formatPhoneNumber()"
      [maxLength]="40"
      [name]="fieldName"
      [formlyAttributes]="field"
      [class.is-invalid]="showError"
      [formControl]="formControl"
      [autocomplete]="autocomplete"
    />
  `,
  standalone: true,
  imports: [ClickOutsideDirective, NgClass, NgFor, ReactiveFormsModule, FormlyModule, KeyValuePipe],
})
export class RktFormInputPhoneComponent extends FieldType<FieldTypeConfig<InputPhoneProps>> implements OnInit {
  @HostBinding('class.rkt-form-input-field') commonClass = true;

  @HostBinding('class.rkt-form-input-phone-field') phoneClass = true;

  @ViewChild('inputRef') inputRef!: ElementRef<HTMLInputElement>;

  isOpened = false;

  countryList!: Record<string, InputPhoneCountry>;

  selectedCountry!: InputPhoneCountry;

  get autocomplete() {
    return this.props.autocomplete ?? 'off';
  }

  get fieldName() {
    return this.field.name ?? this.props.label?.toLowerCase().replace(/\s/g, '-') ?? '';
  }

  ngOnInit(): void {
    this.initCountryList();
    const formValue = this.formControl.value;
    const inputCountry = detectCountryFromPhoneNumber(formValue);
    this.setSelectedCountry(inputCountry?.country ?? head(this.props?.countryRestrictions) ?? 'US');
    if (formValue) this.parsePhoneNumber(formValue);
  }

  initCountryList(): void {
    this.countryList = this.props?.countryRestrictions?.length ? filterCountryListByCode(this.props.countryRestrictions) : countries;
  }

  @HostListener('document:click', ['$event'])
  formatPhoneNumber(): void {
    const formValue = this.formControl.value;
    try {
      const inputCountry = detectCountryFromPhoneNumber(formValue)?.country;
      if (inputCountry) this.setSelectedCountry(inputCountry);
      this.parsePhoneNumber(formValue);
    } catch (_error) {
      this.formControl.setValue(formValue?.replace(/ /g, '') || null);
    }
  }

  parsePhoneNumber(phoneNumber: string): void {
    const parsedPhoneNumber = parsePhoneNumberWithError(phoneNumber, this.selectedCountry.code);
    if (parsedPhoneNumber) this.formControl.setValue(parsedPhoneNumber.formatInternational());
  }

  setSelectedCountry(countryCode: CountryCode): void {
    const country = getCountryDetails(countryCode);
    const formValue = this.formControl.value;
    const prevCountryDialCode = this.selectedCountry?.dialCode;

    if (!country) {
      return;
    }

    this.selectedCountry = country;
    this.formControl.setValue(null);

    if (this.props.shouldKeepPhoneNumber && formValue) {
      this.formControl.setValue(formValue.replace(/ /g, '').replace(`+${prevCountryDialCode}`, `+${country.dialCode}`));
    }
  }

  onCountrySelect(country: InputPhoneCountry): void {
    this.setSelectedCountry(country.code);
    this.hideDropdown();
    this.inputRef.nativeElement.focus();
  }

  toggleDropdown(): void {
    this.isOpened = !this.isOpened;
  }

  hideDropdown(): void {
    this.isOpened = false;
  }
}
