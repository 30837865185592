import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';

import { RktIconComponent } from '../../icon';
import { SelectMultiProps } from '../form.types';

@Component({
  selector: 'rkt-form-select',
  template: `
    <ng-select
      class="rkt-form-select rkt-form-select-multi"
      [class.is-invalid]="showError"
      [items]="props.options | formlySelectOptions: field | async"
      [placeholder]="props.placeholder || ''"
      [virtualScroll]="props.virtualScroll ?? true"
      [tabIndex]="props.tabindex || 0"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [bindLabel]="labelProp"
      [bindValue]="valueProp"
      [clearable]="true"
      [multiple]="true"
      [searchable]="true"
      (add)="repositionDropdown()"
      (remove)="repositionDropdown()"
      [closeOnSelect]="false"
      [maxSelectedItems]="maxSelectedItemsProp"
      appendTo="body"
    >
      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <span class="rkt-value-label">{{ item[labelProp] }}</span>
        <rkt-icon name="close" class="rkt-value-clear-btn" (click)="clear(item)"></rkt-icon>
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-disabled="disabled">
        <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: item, item$: item$, index: index }"> </ng-container>
      </ng-template>

      <ng-template #optionTemplate let-item let-item$="item$" let-index="index">
        <div class="option-content" [class.disabled]="maxItemsSelected && !item$.selected">
          <input hidden id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" />
          <rkt-icon [name]="item$.selected ? 'checkbox-on' : 'checkbox-off'" class="rkt-value-checkbox-icon"></rkt-icon>
          {{ item.label }}
        </div>
      </ng-template>

      <ng-template ng-notfound-tmp>
        <div class="ng-option ng-option-disabled">
          <div class="option-content">No items found</div>
        </div>
      </ng-template>
    </ng-select>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgSelectModule,
    ReactiveFormsModule,
    FormlyModule,
    RktIconComponent,
    NgTemplateOutlet,
    FormsModule,
    AsyncPipe,
    FormlySelectModule,
  ],
})
export class RktFormSelectMultiComponent extends FieldType<FieldTypeConfig<SelectMultiProps>> {
  @ViewChild(NgSelectComponent, { static: true }) public ngSelectComponent!: NgSelectComponent;

  @HostBinding('class.rkt-form-select-field') commonClass = true;

  get labelProp(): string {
    return this.props.labelProp || 'label';
  }

  get valueProp(): string {
    return this.props.valueProp || 'value';
  }

  get maxSelectedItemsProp(): number {
    return this.props.maxSelectedItems || 1000000;
  }

  get maxItemsSelected(): boolean {
    return this.maxSelectedItemsProp <= this.formControl.value?.length;
  }

  public repositionDropdown() {
    if (this.ngSelectComponent?.dropdownPanel) {
      setTimeout(() => this.ngSelectComponent.dropdownPanel?.adjustPosition(), 0);
    }
  }
}
