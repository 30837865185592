<app-attention-modal [type]="confirmType" [title]="title" [messageTemplate]="message" [buttonsTemplate]="buttons"></app-attention-modal>

<ng-template #message>
  <div [innerHTML]="text"></div>

  @if (nameConfirmation) {
    <form [formGroup]="confirmForm" class="confirm-name-form">
      <rkt-form [form]="confirmForm" [fields]="formFields" [model]="formModel"></rkt-form>
    </form>
  }
</ng-template>

<ng-template #buttons>
  <button rktButton btnType="tertiary" title="Cancel" (click)="activeModal.close()">Cancel</button>
  <button
    rktButton
    [disabled]="nameConfirmation ? nameConfirmation.value.trim() !== formModel['name']?.trim() : false"
    [class.error-btn]="confirmType === 'error'"
    [title]="confirmBtnText || 'Confirm'"
    (click)="activeModal.close(true)"
  >
    {{ confirmBtnText || 'Confirm' }}
  </button>
</ng-template>
