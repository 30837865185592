import { NgIf } from '@angular/common';
import { Component, ElementRef, HostBinding, HostListener, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';

import { TextareaProps } from '../form.types';

@Component({
  selector: 'rkt-form-textarea',
  template: `
    <textarea
      #textareaField
      [formControl]="formControl"
      [name]="fieldName"
      class="rkt-form-textarea"
      [formlyAttributes]="field"
      [class.is-invalid]="showError"
    ></textarea>
    <span class="rkt-form-textarea-counter" *ngIf="displayCounter">
      {{ formControl.value?.length || '0' }}/{{ field.props.maxLength }}
    </span>
  `,
  standalone: true,
  imports: [ReactiveFormsModule, FormlyModule, NgIf],
})
export class RktFormTextareaComponent extends FieldType<FieldTypeConfig<TextareaProps>> {
  @ViewChild('textareaField') textareaField!: ElementRef;

  @HostBinding('class.rkt-form-textarea-field') commonClass = true;

  @HostBinding('class.rkt-form-textarea-field-with-counter') get displayCounter(): boolean {
    return !!this.props?.withCounter && !!this.props?.maxLength;
  }

  @HostListener('click', ['$event.target'])
  onClick() {
    this.textareaField?.nativeElement.focus();
  }

  get fieldName() {
    return this.field.name ?? (this.props.label?.toLowerCase().replace(/\s/g, '-') || '');
  }
}
