import { NgClass } from '@angular/common';
import { Component } from '@angular/core';

import { TooltipPosition, TooltipTheme } from './tooltip.enums';

@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class TooltipComponent {
  position: TooltipPosition = 'above';
  theme: TooltipTheme = 'dark';
  tooltip = '';
  left = 0;
  top = 0;
  visible = false;
}
