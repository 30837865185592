import { AsYouType, type CountryCode, type PhoneNumber, parsePhoneNumberWithError } from 'libphonenumber-js';
import { isEmpty, reduce } from 'lodash-es';

import { InputPhoneCountry } from '../form.types';

export const countries: Record<string, InputPhoneCountry> = {
  AF: {
    id: 1,
    name: 'Afghanistan',
    currency: 'AFN',
    dialCode: 93,
    capital: 'Kabul',
    code: 'AF',
    iso3: 'AFG',
    number: 4,
  },
  AL: {
    id: 3,
    name: 'Albania',
    currency: 'ALL',
    dialCode: 355,
    capital: 'Tirana',
    code: 'AL',
    iso3: 'ALB',
    number: 8,
  },
  DZ: {
    id: 12,
    name: 'Algeria',
    currency: 'DZD',
    dialCode: 213,
    capital: 'Algiers',
    code: 'DZ',
    iso3: 'DZA',
    number: 12,
  },
  AS: {
    id: 201,
    name: 'American Samoa',
    currency: 'USD',
    dialCode: 1684,
    capital: 'Pago Pago',
    code: 'AS',
    iso3: 'ASM',
    number: 16,
  },
  AD: {
    id: 5,
    name: 'Andorra',
    currency: 'EUR',
    dialCode: 376,
    capital: 'Andorra la Vella',
    code: 'AD',
    iso3: 'AND',
    number: 20,
  },
  AO: {
    id: 6,
    name: 'Angola',
    currency: 'AOA',
    dialCode: 244,
    capital: 'Luanda',
    code: 'AO',
    iso3: 'AGO',
    number: 24,
  },
  AI: {
    id: 7,
    name: 'Anguilla',
    currency: 'XCD',
    dialCode: 1264,
    capital: 'The Valley',
    code: 'AI',
    iso3: 'AIA',
    number: 660,
  },
  AG: {
    id: 9,
    name: 'Antigua and Barbuda',
    currency: 'XCD',
    dialCode: 1268,
    capital: "St. John's",
    code: 'AG',
    iso3: 'ATG',
    number: 28,
  },
  AR: {
    id: 13,
    name: 'Argentina',
    currency: 'ARS',
    dialCode: 54,
    capital: 'Buenos Aires',
    code: 'AR',
    iso3: 'ARG',
    number: 32,
  },
  AM: {
    id: 14,
    name: 'Armenia',
    currency: 'AMD',
    dialCode: 374,
    capital: 'Yerevan',
    code: 'AM',
    iso3: 'ARM',
    number: 51,
  },
  AW: {
    id: 15,
    name: 'Aruba',
    currency: 'AWG',
    dialCode: 297,
    capital: 'Oranjestad',
    code: 'AW',
    iso3: 'ABW',
    number: 533,
  },
  AU: {
    id: 16,
    name: 'Australia',
    currency: 'AUD',
    dialCode: 61,
    capital: 'Canberra',
    code: 'AU',
    iso3: 'AUS',
    number: 36,
  },
  AT: {
    id: 17,
    name: 'Austria',
    currency: 'EUR',
    dialCode: 43,
    capital: 'Vienna',
    code: 'AT',
    iso3: 'AUT',
    number: 40,
  },
  AZ: {
    id: 18,
    name: 'Azerbaijan',
    currency: 'AZN',
    dialCode: 994,
    capital: 'Baku',
    code: 'AZ',
    iso3: 'AZE',
    number: 31,
  },
  BS: {
    id: 19,
    name: 'Bahamas, The',
    currency: 'BSD',
    dialCode: 1242,
    capital: 'Nassau',
    code: 'BS',
    iso3: 'BHS',
    number: 44,
  },
  BH: {
    id: 20,
    name: 'Bahrain',
    currency: 'BHD',
    dialCode: 973,
    capital: 'Manama',
    code: 'BH',
    iso3: 'BHR',
    number: 48,
  },
  BD: {
    id: 21,
    name: 'Bangladesh',
    currency: 'BDT',
    dialCode: 880,
    capital: 'Dhaka',
    code: 'BD',
    iso3: 'BGD',
    number: 50,
  },
  BB: {
    id: 22,
    name: 'Barbados',
    currency: 'BBD',
    dialCode: 1246,
    capital: 'Bridgetown',
    code: 'BB',
    iso3: 'BRB',
    number: 52,
  },
  BY: {
    id: 27,
    name: 'Belarus',
    currency: 'BYN',
    dialCode: 375,
    capital: 'Minsk',
    code: 'BY',
    iso3: 'BLR',
    number: 112,
  },
  BE: {
    id: 23,
    name: 'Belgium',
    currency: 'EUR',
    dialCode: 32,
    capital: 'Brussels',
    code: 'BE',
    iso3: 'BEL',
    number: 56,
  },
  BZ: {
    id: 24,
    name: 'Belize',
    currency: 'BZD',
    dialCode: 501,
    capital: 'Belmopan',
    code: 'BZ',
    iso3: 'BLZ',
    number: 84,
  },
  BJ: {
    id: 25,
    name: 'Benin',
    currency: 'XOF',
    dialCode: 229,
    capital: 'Porto-Novo',
    code: 'BJ',
    iso3: 'BEN',
    number: 204,
  },
  BM: {
    id: 26,
    name: 'Bermuda',
    currency: 'BMD',
    dialCode: 1441,
    capital: 'Hamilton',
    code: 'BM',
    iso3: 'BMU',
    number: 60,
  },
  BT: {
    id: 36,
    name: 'Bhutan',
    currency: 'BTN',
    dialCode: 975,
    capital: 'Thimphu',
    code: 'BT',
    iso3: 'BTN',
    number: 64,
  },
  VE: {
    id: 248,
    name: 'Bolivarian Republic of Venezuela',
    currency: 'VEF',
    dialCode: 58,
    capital: 'Caracas',
    code: 'VE',
    iso3: 'VEN',
    number: 862,
  },
  BO: {
    id: 28,
    name: 'Bolivia',
    currency: 'BOB',
    dialCode: 591,
    capital: 'Sucre',
    code: 'BO',
    iso3: 'BOL',
    number: 68,
  },
  BQ: {
    id: 199,
    name: 'Bonaire, Sint Eustatius and Saba',
    currency: 'USD',
    dialCode: 599,
    capital: 'Kralendijk',
    code: 'BQ',
    iso3: 'BES',
    number: 535,
  },
  BA: {
    id: 29,
    name: 'Bosnia and Herzegovina',
    currency: 'BAM',
    dialCode: 387,
    capital: 'Sarajevo',
    code: 'BA',
    iso3: 'BIH',
    number: 70,
  },
  BW: {
    id: 30,
    name: 'Botswana',
    currency: 'BWP',
    dialCode: 267,
    capital: 'Gaborone',
    code: 'BW',
    iso3: 'BWA',
    number: 72,
  },
  BR: {
    id: 31,
    name: 'Brazil',
    currency: 'BRL',
    dialCode: 55,
    capital: 'Brasilia',
    code: 'BR',
    iso3: 'BRA',
    number: 76,
  },
  IO: {
    id: 231,
    name: 'British Indian Ocean Territory',
    currency: 'USD',
    dialCode: 246,
    capital: 'Diego Garcia',
    code: 'IO',
    iso3: 'IOT',
    number: 86,
  },
  BN: {
    id: 32,
    name: 'Brunei',
    currency: 'BND',
    dialCode: 673,
    capital: 'Bandar Seri Begawan',
    code: 'BN',
    iso3: 'BRN',
    number: 96,
  },
  BG: {
    id: 33,
    name: 'Bulgaria',
    currency: 'BGN',
    dialCode: 359,
    capital: 'Sofia',
    code: 'BG',
    iso3: 'BGR',
    number: 100,
  },
  BF: {
    id: 34,
    name: 'Burkina Faso',
    currency: 'XOF',
    dialCode: 226,
    capital: 'Ouagadougou',
    code: 'BF',
    iso3: 'BFA',
    number: 854,
  },
  BI: {
    id: 35,
    name: 'Burundi',
    currency: 'BIF',
    dialCode: 257,
    capital: 'Bujumbura',
    code: 'BI',
    iso3: 'BDI',
    number: 108,
  },
  CV: {
    id: 37,
    name: 'Cabo Verde',
    currency: 'CVE',
    dialCode: 238,
    capital: 'Praia',
    code: 'CV',
    iso3: 'CPV',
    number: 132,
  },
  KH: {
    id: 39,
    name: 'Cambodia',
    currency: 'KHR',
    dialCode: 855,
    capital: 'Phnom Penh',
    code: 'KH',
    iso3: 'KHM',
    number: 116,
  },
  CM: {
    id: 38,
    name: 'Cameroon',
    currency: 'XAF',
    dialCode: 237,
    capital: 'Yaounde',
    code: 'CM',
    iso3: 'CMR',
    number: 120,
  },
  CA: {
    id: 40,
    name: 'Canada',
    currency: 'CAD',
    dialCode: 1,
    capital: 'Ottawa',
    code: 'CA',
    iso3: 'CAN',
    number: 124,
    states: [
      {
        label: 'Alberta',
        value: 'AB',
      },
      {
        label: 'British Columbia',
        value: 'BC',
      },
      {
        label: 'Manitoba',
        value: 'MB',
      },
      {
        label: 'New Brunswick',
        value: 'NB',
      },
      {
        label: 'Newfoundland and Labrador',
        value: 'NL',
      },
      {
        label: 'Northwest Territories',
        value: 'NT',
      },
      {
        label: 'Nova Scotia',
        value: 'NS',
      },
      {
        label: 'Nunavut',
        value: 'NU',
      },
      {
        label: 'Ontario',
        value: 'ON',
      },
      {
        label: 'Prince Edward Island',
        value: 'PE',
      },
      {
        label: 'Quebec',
        value: 'QC',
      },
      {
        label: 'Saskatchewan',
        value: 'SK',
      },
      {
        label: 'Yukon Territory',
        value: 'YT',
      },
    ],
  },
  KY: {
    id: 107,
    name: 'Cayman Islands',
    currency: 'KYD',
    dialCode: 1345,
    capital: 'George Town',
    code: 'KY',
    iso3: 'CYM',
    number: 136,
  },
  CF: {
    id: 191,
    name: 'Central African Republic',
    currency: 'XAF',
    dialCode: 236,
    capital: 'Bangui',
    code: 'CF',
    iso3: 'CAF',
    number: 140,
  },
  TD: {
    id: 43,
    name: 'Chad',
    currency: 'XAF',
    dialCode: 235,
    capital: "N'Djamena",
    code: 'TD',
    iso3: 'TCD',
    number: 148,
  },
  CL: {
    id: 44,
    name: 'Chile',
    currency: 'CLP',
    dialCode: 56,
    capital: 'Santiago',
    code: 'CL',
    iso3: 'CHL',
    number: 152,
  },
  CN: {
    id: 45,
    name: 'China',
    currency: 'CNY',
    dialCode: 86,
    capital: 'Beijing',
    code: 'CN',
    iso3: 'CHN',
    number: 156,
  },
  CX: {
    id: 104,
    name: 'Christmas Island',
    currency: 'AUD',
    dialCode: 61,
    capital: 'Flying Fish Cove',
    code: 'CX',
    iso3: 'CXR',
    number: 162,
  },
  CC: {
    id: 108,
    name: 'Cocos (Keeling) Islands',
    currency: 'AUD',
    dialCode: 672,
    capital: 'West Island',
    code: 'CC',
    iso3: 'CCK',
    number: 166,
  },
  CO: {
    id: 48,
    name: 'Colombia',
    currency: 'COP',
    dialCode: 57,
    capital: 'Bogota',
    code: 'CO',
    iso3: 'COL',
    number: 170,
  },
  KM: {
    id: 49,
    name: 'Comoros',
    currency: 'KMF',
    dialCode: 269,
    capital: 'Moroni',
    code: 'KM',
    iso3: 'COM',
    number: 174,
  },
  CG: {
    id: 50,
    name: 'Congo',
    currency: 'XAF',
    dialCode: 242,
    capital: 'Brazzaville',
    code: 'CG',
    iso3: 'COG',
    number: 178,
  },
  CD: {
    id: 192,
    name: 'Congo (DRC)',
    currency: 'CDF',
    dialCode: 242,
    capital: 'Kinshasa',
    code: 'CD',
    iso3: 'COD',
    number: 180,
  },
  CK: {
    id: 109,
    name: 'Cook Islands',
    currency: 'NZD',
    dialCode: 682,
    capital: 'Avarua',
    code: 'CK',
    iso3: 'COK',
    number: 184,
  },
  CR: {
    id: 54,
    name: 'Costa Rica',
    currency: 'CRC',
    dialCode: 506,
    capital: 'San Jose',
    code: 'CR',
    iso3: 'CRI',
    number: 188,
  },
  HR: {
    id: 55,
    name: 'Croatia',
    currency: 'HRK',
    dialCode: 385,
    capital: 'Zagreb',
    code: 'HR',
    iso3: 'HRV',
    number: 191,
  },
  CU: {
    id: 56,
    name: 'Cuba',
    currency: 'CUP',
    dialCode: 53,
    capital: 'Havana',
    code: 'CU',
    iso3: 'CUB',
    number: 192,
  },
  CW: {
    id: 57,
    name: 'Curaçao',
    currency: 'ANG',
    dialCode: 599,
    capital: 'Willemstad',
    code: 'CW',
    iso3: 'CUW',
    number: 531,
  },
  CY: {
    id: 46,
    name: 'Cyprus',
    currency: 'EUR',
    dialCode: 357,
    capital: 'Nicosia',
    code: 'CY',
    iso3: 'CYP',
    number: 196,
  },
  CZ: {
    id: 194,
    name: 'Czech Republic',
    currency: 'CZK',
    dialCode: 420,
    capital: 'Prague',
    code: 'CZ',
    iso3: 'CZE',
    number: 203,
  },
  CI: {
    id: 53,
    name: "Côte d'Ivoire",
    currency: 'XOF',
    dialCode: 225,
    capital: 'Yamoussoukro',
    code: 'CI',
    iso3: 'CIV',
    number: 384,
  },
  TL: {
    id: 233,
    name: 'Democratic Republic of Timor-Leste',
    currency: 'USD',
    dialCode: 670,
    capital: 'Dili',
    code: 'TL',
    iso3: 'TLS',
    number: 626,
  },
  DK: {
    id: 58,
    name: 'Denmark',
    currency: 'DKK',
    dialCode: 45,
    capital: 'Copenhagen',
    code: 'DK',
    iso3: 'DNK',
    number: 208,
  },
  DJ: {
    id: 59,
    name: 'Djibouti',
    currency: 'DJF',
    dialCode: 253,
    capital: 'Djibouti',
    code: 'DJ',
    iso3: 'DJI',
    number: 262,
  },
  DM: {
    id: 60,
    name: 'Dominica',
    currency: 'XCD',
    dialCode: 1767,
    capital: 'Roseau',
    code: 'DM',
    iso3: 'DMA',
    number: 212,
  },
  DO: {
    id: 193,
    name: 'Dominican Republic',
    currency: 'DOP',
    dialCode: 1809,
    capital: 'Santo Domingo',
    code: 'DO',
    iso3: 'DOM',
    number: 214,
  },
  EC: {
    id: 64,
    name: 'Ecuador',
    currency: 'USD',
    dialCode: 593,
    capital: 'Quito',
    code: 'EC',
    iso3: 'ECU',
    number: 218,
  },
  EG: {
    id: 61,
    name: 'Egypt',
    currency: 'EGP',
    dialCode: 20,
    capital: 'Cairo',
    code: 'EG',
    iso3: 'EGY',
    number: 818,
  },
  SV: {
    id: 62,
    name: 'El Salvador',
    currency: 'USD',
    dialCode: 503,
    capital: 'San Salvador',
    code: 'SV',
    iso3: 'SLV',
    number: 222,
  },
  GQ: {
    id: 91,
    name: 'Equatorial Guinea',
    currency: 'XAF',
    dialCode: 240,
    capital: 'Malabo',
    code: 'GQ',
    iso3: 'GNQ',
    number: 226,
  },
  ER: {
    id: 65,
    name: 'Eritrea',
    currency: 'ERN',
    dialCode: 291,
    capital: 'Asmara',
    code: 'ER',
    iso3: 'ERI',
    number: 232,
  },
  EE: {
    id: 70,
    name: 'Estonia',
    currency: 'EUR',
    dialCode: 372,
    capital: 'Tallinn',
    code: 'EE',
    iso3: 'EST',
    number: 233,
  },
  ET: {
    id: 71,
    name: 'Ethiopia',
    currency: 'ETB',
    dialCode: 251,
    capital: 'Addis Ababa',
    code: 'ET',
    iso3: 'ETH',
    number: 231,
  },
  FK: {
    id: 110,
    name: 'Falkland Islands (Islas Malvinas)',
    currency: 'FKP',
    dialCode: 500,
    capital: 'Stanley',
    code: 'FK',
    iso3: 'FLK',
    number: 238,
  },
  FO: {
    id: 111,
    name: 'Faroe Islands',
    currency: 'DKK',
    dialCode: 298,
    capital: 'Torshavn',
    code: 'FO',
    iso3: 'FRO',
    number: 234,
  },
  FJ: {
    id: 72,
    name: 'Fiji Islands',
    currency: 'FJD',
    dialCode: 679,
    capital: 'Suva',
    code: 'FJ',
    iso3: 'FJI',
    number: 242,
  },
  FI: {
    id: 74,
    name: 'Finland',
    currency: 'EUR',
    dialCode: 358,
    capital: 'Helsinki',
    code: 'FI',
    iso3: 'FIN',
    number: 246,
  },
  FR: {
    id: 75,
    name: 'France',
    currency: 'EUR',
    dialCode: 33,
    capital: 'Paris',
    code: 'FR',
    iso3: 'FRA',
    number: 250,
  },
  GF: {
    id: 89,
    name: 'French Guiana',
    currency: 'EUR',
    dialCode: 594,
    capital: 'Cayenne',
    code: 'GF',
    iso3: 'GUF',
    number: 254,
  },
  PF: {
    id: 184,
    name: 'French Polynesia',
    currency: 'XPF',
    dialCode: 689,
    capital: 'Papeete',
    code: 'PF',
    iso3: 'PYF',
    number: 258,
  },
  GA: {
    id: 76,
    name: 'Gabon',
    currency: 'XAF',
    dialCode: 241,
    capital: 'Libreville',
    code: 'GA',
    iso3: 'GAB',
    number: 266,
  },
  GM: {
    id: 77,
    name: 'Gambia, The',
    currency: 'GMD',
    dialCode: 220,
    capital: 'Banjul',
    code: 'GM',
    iso3: 'GMB',
    number: 270,
  },
  GE: {
    id: 79,
    name: 'Georgia',
    currency: 'GEL',
    dialCode: 995,
    capital: 'Tbilisi',
    code: 'GE',
    iso3: 'GEO',
    number: 268,
  },
  DE: {
    id: 4,
    name: 'Germany',
    currency: 'EUR',
    dialCode: 49,
    capital: 'Berlin',
    code: 'DE',
    iso3: 'DEU',
    number: 276,
  },
  GH: {
    id: 78,
    name: 'Ghana',
    currency: 'GHS',
    dialCode: 233,
    capital: 'Accra',
    code: 'GH',
    iso3: 'GHA',
    number: 288,
  },
  GI: {
    id: 80,
    name: 'Gibraltar',
    currency: 'GIP',
    dialCode: 350,
    capital: 'Gibraltar',
    code: 'GI',
    iso3: 'GIB',
    number: 292,
  },
  GR: {
    id: 82,
    name: 'Greece',
    currency: 'EUR',
    dialCode: 30,
    capital: 'Athens',
    code: 'GR',
    iso3: 'GRC',
    number: 300,
  },
  GL: {
    id: 83,
    name: 'Greenland',
    currency: 'DKK',
    dialCode: 299,
    capital: 'Nuuk',
    code: 'GL',
    iso3: 'GRL',
    number: 304,
  },
  GD: {
    id: 81,
    name: 'Grenada',
    currency: 'XCD',
    dialCode: 1473,
    capital: "St. George's",
    code: 'GD',
    iso3: 'GRD',
    number: 308,
  },
  GP: {
    id: 84,
    name: 'Guadeloupe',
    currency: 'EUR',
    dialCode: 590,
    capital: 'Basse-Terre',
    code: 'GP',
    iso3: 'GLP',
    number: 312,
  },
  GU: {
    id: 85,
    name: 'Guam',
    currency: 'USD',
    dialCode: 1671,
    capital: 'Hagatna',
    code: 'GU',
    iso3: 'GUM',
    number: 316,
  },
  GT: {
    id: 86,
    name: 'Guatemala',
    currency: 'GTQ',
    dialCode: 502,
    capital: 'Guatemala City',
    code: 'GT',
    iso3: 'GTM',
    number: 320,
  },
  GG: {
    id: 87,
    name: 'Guernsey',
    currency: 'GBP',
    dialCode: 44,
    capital: 'St Peter Port',
    code: 'GG',
    iso3: 'GGY',
    number: 831,
  },
  GN: {
    id: 90,
    name: 'Guinea',
    currency: 'GNF',
    dialCode: 224,
    capital: 'Conakry',
    code: 'GN',
    iso3: 'GIN',
    number: 324,
  },
  GW: {
    id: 92,
    name: 'Guinea-Bissau',
    currency: 'XOF',
    dialCode: 245,
    capital: 'Bissau',
    code: 'GW',
    iso3: 'GNB',
    number: 624,
  },
  GY: {
    id: 88,
    name: 'Guyana',
    currency: 'GYD',
    dialCode: 592,
    capital: 'Georgetown',
    code: 'GY',
    iso3: 'GUY',
    number: 328,
  },
  HT: {
    id: 93,
    name: 'Haiti',
    currency: 'HTG',
    dialCode: 509,
    capital: 'Port-au-Prince',
    code: 'HT',
    iso3: 'HTI',
    number: 332,
  },
  HN: {
    id: 95,
    name: 'Honduras',
    currency: 'HNL',
    dialCode: 504,
    capital: 'Tegucigalpa',
    code: 'HN',
    iso3: 'HND',
    number: 340,
  },
  HK: {
    id: 96,
    name: 'Hong Kong SAR',
    currency: 'HKD',
    dialCode: 852,
    capital: 'Hong Kong',
    code: 'HK',
    iso3: 'HKG',
    number: 344,
  },
  HU: {
    id: 97,
    name: 'Hungary',
    currency: 'HUF',
    dialCode: 36,
    capital: 'Budapest',
    code: 'HU',
    iso3: 'HUN',
    number: 348,
  },
  IS: {
    id: 126,
    name: 'Iceland',
    currency: 'ISK',
    dialCode: 354,
    capital: 'Reykjavik',
    code: 'IS',
    iso3: 'ISL',
    number: 352,
  },
  IN: {
    id: 121,
    name: 'India',
    currency: 'INR',
    dialCode: 91,
    capital: 'New Delhi',
    code: 'IN',
    iso3: 'IND',
    number: 356,
  },
  ID: {
    id: 122,
    name: 'Indonesia',
    currency: 'IDR',
    dialCode: 62,
    capital: 'Jakarta',
    code: 'ID',
    iso3: 'IDN',
    number: 360,
  },
  IR: {
    id: 123,
    name: 'Iran',
    currency: 'IRR',
    dialCode: 98,
    capital: 'Tehran',
    code: 'IR',
    iso3: 'IRN',
    number: 364,
  },
  IQ: {
    id: 124,
    name: 'Iraq',
    currency: 'IQD',
    dialCode: 964,
    capital: 'Baghdad',
    code: 'IQ',
    iso3: 'IRQ',
    number: 368,
  },
  IE: {
    id: 125,
    name: 'Ireland',
    currency: 'EUR',
    dialCode: 353,
    capital: 'Dublin',
    code: 'IE',
    iso3: 'IRL',
    number: 372,
  },
  IL: {
    id: 127,
    name: 'Israel',
    currency: 'ILS',
    dialCode: 972,
    capital: 'Jerusalem',
    code: 'IL',
    iso3: 'ISR',
    number: 376,
  },
  IT: {
    id: 128,
    name: 'Italy',
    currency: 'EUR',
    dialCode: 39,
    capital: 'Rome',
    code: 'IT',
    iso3: 'ITA',
    number: 380,
  },
  JM: {
    id: 129,
    name: 'Jamaica',
    currency: 'JMD',
    dialCode: 1876,
    capital: 'Kingston',
    code: 'JM',
    iso3: 'JAM',
    number: 388,
  },
  SJ: {
    id: 225,
    name: 'Jan Mayen',
    currency: 'NOK',
    dialCode: 47,
    capital: 'Longyearbyen',
    code: 'SJ',
    iso3: 'SJM',
    number: 744,
  },
  JP: {
    id: 130,
    name: 'Japan',
    currency: 'JPY',
    dialCode: 81,
    capital: 'Tokyo',
    code: 'JP',
    iso3: 'JPN',
    number: 392,
  },
  JE: {
    id: 131,
    name: 'Jersey',
    currency: 'GBP',
    dialCode: 44,
    capital: 'Saint Helier',
    code: 'JE',
    iso3: 'JEY',
    number: 832,
  },
  JO: {
    id: 132,
    name: 'Jordan',
    currency: 'JOD',
    dialCode: 962,
    capital: 'Amman',
    code: 'JO',
    iso3: 'JOR',
    number: 400,
  },
  KZ: {
    id: 42,
    name: 'Kazakhstan',
    currency: 'KZT',
    dialCode: 7,
    capital: 'Astana',
    code: 'KZ',
    iso3: 'KAZ',
    number: 398,
  },
  KE: {
    id: 188,
    name: 'Kenya',
    currency: 'KES',
    dialCode: 254,
    capital: 'Nairobi',
    code: 'KE',
    iso3: 'KEN',
    number: 404,
  },
  KI: {
    id: 133,
    name: 'Kiribati',
    currency: 'AUD',
    dialCode: 686,
    capital: 'Tarawa',
    code: 'KI',
    iso3: 'KIR',
    number: 296,
  },
  KR: {
    id: 52,
    name: 'Korea',
    currency: 'KRW',
    dialCode: 82,
    capital: 'Seoul',
    code: 'KR',
    iso3: 'KOR',
    number: 410,
  },
  XK: {
    id: 134,
    name: 'Kosovo',
    currency: 'EUR',
    dialCode: 383,
    capital: 'Pristina',
    code: 'XK',
    iso3: 'XKX',
    number: 926,
  },
  KW: {
    id: 135,
    name: 'Kuwait',
    currency: 'KWD',
    dialCode: 965,
    capital: 'Kuwait City',
    code: 'KW',
    iso3: 'KWT',
    number: 414,
  },
  KG: {
    id: 189,
    name: 'Kyrgyzstan',
    currency: 'KGS',
    dialCode: 996,
    capital: 'Bishkek',
    code: 'KG',
    iso3: 'KGZ',
    number: 417,
  },
  LA: {
    id: 136,
    name: 'Laos',
    currency: 'LAK',
    dialCode: 856,
    capital: 'Vientiane',
    code: 'LA',
    iso3: 'LAO',
    number: 418,
  },
  LV: {
    id: 138,
    name: 'Latvia',
    currency: 'EUR',
    dialCode: 371,
    capital: 'Riga',
    code: 'LV',
    iso3: 'LVA',
    number: 428,
  },
  LB: {
    id: 139,
    name: 'Lebanon',
    currency: 'LBP',
    dialCode: 961,
    capital: 'Beirut',
    code: 'LB',
    iso3: 'LBN',
    number: 422,
  },
  LS: {
    id: 137,
    name: 'Lesotho',
    currency: 'LSL',
    dialCode: 266,
    capital: 'Maseru',
    code: 'LS',
    iso3: 'LSO',
    number: 426,
  },
  LR: {
    id: 140,
    name: 'Liberia',
    currency: 'LRD',
    dialCode: 231,
    capital: 'Monrovia',
    code: 'LR',
    iso3: 'LBR',
    number: 430,
  },
  LY: {
    id: 141,
    name: 'Libya',
    currency: 'LYD',
    dialCode: 218,
    capital: 'Tripolis',
    code: 'LY',
    iso3: 'LBY',
    number: 434,
  },
  LI: {
    id: 142,
    name: 'Liechtenstein',
    currency: 'CHF',
    dialCode: 423,
    capital: 'Vaduz',
    code: 'LI',
    iso3: 'LIE',
    number: 438,
  },
  LT: {
    id: 143,
    name: 'Lithuania',
    currency: 'EUR',
    dialCode: 370,
    capital: 'Vilnius',
    code: 'LT',
    iso3: 'LTU',
    number: 440,
  },
  LU: {
    id: 144,
    name: 'Luxembourg',
    currency: 'EUR',
    dialCode: 352,
    capital: 'Luxembourg',
    code: 'LU',
    iso3: 'LUX',
    number: 442,
  },
  MO: {
    id: 145,
    name: 'Macao SAR',
    currency: 'MOP',
    dialCode: 853,
    capital: 'Macao',
    code: 'MO',
    iso3: 'MAC',
    number: 446,
  },
  MK: {
    id: 146,
    name: 'Macedonia, Former Yugoslav Republic of',
    currency: 'MKD',
    dialCode: 389,
    capital: 'Skopje',
    code: 'MK',
    iso3: 'MKD',
    number: 807,
  },
  MG: {
    id: 147,
    name: 'Madagascar',
    currency: 'MGA',
    dialCode: 261,
    capital: 'Antananarivo',
    code: 'MG',
    iso3: 'MDG',
    number: 450,
  },
  MW: {
    id: 149,
    name: 'Malawi',
    currency: 'MWK',
    dialCode: 265,
    capital: 'Lilongwe',
    code: 'MW',
    iso3: 'MWI',
    number: 454,
  },
  MY: {
    id: 148,
    name: 'Malaysia',
    currency: 'MYR',
    dialCode: 60,
    capital: 'Kuala Lumpur',
    code: 'MY',
    iso3: 'MYS',
    number: 458,
  },
  MV: {
    id: 150,
    name: 'Maldives',
    currency: 'MVR',
    dialCode: 960,
    capital: 'Male',
    code: 'MV',
    iso3: 'MDV',
    number: 462,
  },
  ML: {
    id: 151,
    name: 'Mali',
    currency: 'XOF',
    dialCode: 223,
    capital: 'Bamako',
    code: 'ML',
    iso3: 'MLI',
    number: 466,
  },
  MT: {
    id: 152,
    name: 'Malta',
    currency: 'EUR',
    dialCode: 356,
    capital: 'Valletta',
    code: 'MT',
    iso3: 'MLT',
    number: 470,
  },
  IM: {
    id: 101,
    name: 'Man, Isle of',
    currency: 'GBP',
    dialCode: 44,
    capital: 'Douglas, Isle of Man',
    code: 'IM',
    iso3: 'IMN',
    number: 833,
  },
  MH: {
    id: 114,
    name: 'Marshall Islands',
    currency: 'USD',
    dialCode: 692,
    capital: 'Majuro',
    code: 'MH',
    iso3: 'MHL',
    number: 584,
  },
  MQ: {
    id: 154,
    name: 'Martinique',
    currency: 'EUR',
    dialCode: 596,
    capital: 'Fort-de-France',
    code: 'MQ',
    iso3: 'MTQ',
    number: 474,
  },
  MR: {
    id: 156,
    name: 'Mauritania',
    currency: 'MRO',
    dialCode: 222,
    capital: 'Nouakchott',
    code: 'MR',
    iso3: 'MRT',
    number: 478,
  },
  MU: {
    id: 155,
    name: 'Mauritius',
    currency: 'MUR',
    dialCode: 230,
    capital: 'Port Louis',
    code: 'MU',
    iso3: 'MUS',
    number: 480,
  },
  YT: {
    id: 157,
    name: 'Mayotte',
    currency: 'EUR',
    dialCode: 269,
    capital: 'Mamoudzou',
    code: 'YT',
    iso3: 'MYT',
    number: 175,
  },
  MX: {
    id: 158,
    name: 'Mexico',
    currency: 'MXN',
    dialCode: 52,
    capital: 'Mexico City',
    code: 'MX',
    iso3: 'MEX',
    number: 484,
  },
  FM: {
    id: 159,
    name: 'Micronesia',
    currency: 'USD',
    dialCode: 691,
    capital: 'Palikir',
    code: 'FM',
    iso3: 'FSM',
    number: 583,
  },
  MD: {
    id: 161,
    name: 'Moldova',
    currency: 'MDL',
    dialCode: 373,
    capital: 'Chisinau',
    code: 'MD',
    iso3: 'MDA',
    number: 498,
  },
  MC: {
    id: 162,
    name: 'Monaco',
    currency: 'EUR',
    dialCode: 377,
    capital: 'Monaco',
    code: 'MC',
    iso3: 'MCO',
    number: 492,
  },
  MN: {
    id: 163,
    name: 'Mongolia',
    currency: 'MNT',
    dialCode: 976,
    capital: 'Ulan Bator',
    code: 'MN',
    iso3: 'MNG',
    number: 496,
  },
  ME: {
    id: 164,
    name: 'Montenegro',
    currency: 'EUR',
    dialCode: 382,
    capital: 'Podgorica',
    code: 'ME',
    iso3: 'MNE',
    number: 499,
  },
  MS: {
    id: 165,
    name: 'Montserrat',
    currency: 'XCD',
    dialCode: 1664,
    capital: 'Plymouth',
    code: 'MS',
    iso3: 'MSR',
    number: 500,
  },
  MA: {
    id: 153,
    name: 'Morocco',
    currency: 'MAD',
    dialCode: 212,
    capital: 'Rabat',
    code: 'MA',
    iso3: 'MAR',
    number: 504,
  },
  MZ: {
    id: 160,
    name: 'Mozambique',
    currency: 'MZN',
    dialCode: 258,
    capital: 'Maputo',
    code: 'MZ',
    iso3: 'MOZ',
    number: 508,
  },
  MM: {
    id: 166,
    name: 'Myanmar',
    currency: 'MMK',
    dialCode: 95,
    capital: 'Nay Pyi Taw',
    code: 'MM',
    iso3: 'MMR',
    number: 104,
  },
  NA: {
    id: 167,
    name: 'Namibia',
    currency: 'NAD',
    dialCode: 264,
    capital: 'Windhoek',
    code: 'NA',
    iso3: 'NAM',
    number: 516,
  },
  NR: {
    id: 168,
    name: 'Nauru',
    currency: 'AUD',
    dialCode: 674,
    capital: 'Yaren',
    code: 'NR',
    iso3: 'NRU',
    number: 520,
  },
  NP: {
    id: 169,
    name: 'Nepal',
    currency: 'NPR',
    dialCode: 977,
    capital: 'Kathmandu',
    code: 'NP',
    iso3: 'NPL',
    number: 524,
  },
  NL: {
    id: 94,
    name: 'Netherlands',
    currency: 'EUR',
    dialCode: 31,
    capital: 'Amsterdam',
    code: 'NL',
    iso3: 'NLD',
    number: 528,
  },
  NC: {
    id: 175,
    name: 'New Caledonia',
    currency: 'XPF',
    dialCode: 687,
    capital: 'Noumea',
    code: 'NC',
    iso3: 'NCL',
    number: 540,
  },
  NZ: {
    id: 176,
    name: 'New Zealand',
    currency: 'NZD',
    dialCode: 64,
    capital: 'Wellington',
    code: 'NZ',
    iso3: 'NZL',
    number: 554,
  },
  NI: {
    id: 170,
    name: 'Nicaragua',
    currency: 'NIO',
    dialCode: 505,
    capital: 'Managua',
    code: 'NI',
    iso3: 'NIC',
    number: 558,
  },
  NE: {
    id: 171,
    name: 'Niger',
    currency: 'XOF',
    dialCode: 227,
    capital: 'Niamey',
    code: 'NE',
    iso3: 'NER',
    number: 562,
  },
  NG: {
    id: 172,
    name: 'Nigeria',
    currency: 'NGN',
    dialCode: 234,
    capital: 'Abuja',
    code: 'NG',
    iso3: 'NGA',
    number: 566,
  },
  NU: {
    id: 173,
    name: 'Niue',
    currency: 'NZD',
    dialCode: 683,
    capital: 'Alofi',
    code: 'NU',
    iso3: 'NIU',
    number: 570,
  },
  NF: {
    id: 105,
    name: 'Norfolk Island',
    currency: 'AUD',
    dialCode: 672,
    capital: 'Kingston',
    code: 'NF',
    iso3: 'NFK',
    number: 574,
  },
  KP: {
    id: 51,
    name: 'North Korea',
    currency: 'KPW',
    dialCode: 850,
    capital: 'Pyongyang',
    code: 'KP',
    iso3: 'PRK',
    number: 408,
  },
  MP: {
    id: 113,
    name: 'Northern Mariana Islands',
    currency: 'USD',
    dialCode: 1670,
    capital: 'Saipan',
    code: 'MP',
    iso3: 'MNP',
    number: 580,
  },
  NO: {
    id: 174,
    name: 'Norway',
    currency: 'NOK',
    dialCode: 47,
    capital: 'Oslo',
    code: 'NO',
    iso3: 'NOR',
    number: 578,
  },
  OM: {
    id: 177,
    name: 'Oman',
    currency: 'OMR',
    dialCode: 968,
    capital: 'Muscat',
    code: 'OM',
    iso3: 'OMN',
    number: 512,
  },
  PK: {
    id: 181,
    name: 'Pakistan',
    currency: 'PKR',
    dialCode: 92,
    capital: 'Islamabad',
    code: 'PK',
    iso3: 'PAK',
    number: 586,
  },
  PW: {
    id: 178,
    name: 'Palau',
    currency: 'USD',
    dialCode: 680,
    capital: 'Melekeok',
    code: 'PW',
    iso3: 'PLW',
    number: 585,
  },
  PS: {
    id: 232,
    name: 'Palestinian Authority',
    currency: 'ILS',
    dialCode: 970,
    capital: 'East Jerusalem',
    code: 'PS',
    iso3: 'PSE',
    number: 275,
  },
  PA: {
    id: 179,
    name: 'Panama',
    currency: 'PAB',
    dialCode: 507,
    capital: 'Panama City',
    code: 'PA',
    iso3: 'PAN',
    number: 591,
  },
  PG: {
    id: 180,
    name: 'Papua New Guinea',
    currency: 'PGK',
    dialCode: 675,
    capital: 'Port Moresby',
    code: 'PG',
    iso3: 'PNG',
    number: 598,
  },
  PY: {
    id: 182,
    name: 'Paraguay',
    currency: 'PYG',
    dialCode: 595,
    capital: 'Asuncion',
    code: 'PY',
    iso3: 'PRY',
    number: 600,
  },
  PE: {
    id: 183,
    name: 'Peru',
    currency: 'PEN',
    dialCode: 51,
    capital: 'Lima',
    code: 'PE',
    iso3: 'PER',
    number: 604,
  },
  PH: {
    id: 73,
    name: 'Philippines',
    currency: 'PHP',
    dialCode: 63,
    capital: 'Manila',
    code: 'PH',
    iso3: 'PHL',
    number: 608,
  },
  PL: {
    id: 185,
    name: 'Poland',
    currency: 'PLN',
    dialCode: 48,
    capital: 'Warsaw',
    code: 'PL',
    iso3: 'POL',
    number: 616,
  },
  PT: {
    id: 187,
    name: 'Portugal',
    currency: 'EUR',
    dialCode: 351,
    capital: 'Lisbon',
    code: 'PT',
    iso3: 'PRT',
    number: 620,
  },
  PR: {
    id: 186,
    name: 'Puerto Rico',
    currency: 'USD',
    dialCode: 1787,
    capital: 'San Juan',
    code: 'PR',
    iso3: 'PRI',
    number: 630,
  },
  QA: {
    id: 41,
    name: 'Qatar',
    currency: 'QAR',
    dialCode: 974,
    capital: 'Doha',
    code: 'QA',
    iso3: 'QAT',
    number: 634,
  },
  RE: {
    id: 100,
    name: 'Reunion',
    currency: 'EUR',
    dialCode: 262,
    capital: 'Saint-Denis',
    code: 'RE',
    iso3: 'REU',
    number: 638,
  },
  RO: {
    id: 195,
    name: 'Romania',
    currency: 'RON',
    dialCode: 40,
    capital: 'Bucharest',
    code: 'RO',
    iso3: 'ROM',
    number: 642,
  },
  RU: {
    id: 197,
    name: 'Russia',
    currency: 'RUB',
    dialCode: 70,
    capital: 'Moscow',
    code: 'RU',
    iso3: 'RUS',
    number: 643,
  },
  RW: {
    id: 196,
    name: 'Rwanda',
    currency: 'RWF',
    dialCode: 250,
    capital: 'Kigali',
    code: 'RW',
    iso3: 'RWA',
    number: 646,
  },
  BL: {
    id: 205,
    name: 'Saint Barthélemy',
    currency: 'EUR',
    dialCode: 590,
    capital: 'Gustavia',
    code: 'BL',
    iso3: 'BLM',
    number: 652,
  },
  SH: {
    id: 203,
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    currency: 'SHP',
    dialCode: 290,
    capital: 'Jamestown',
    code: 'SH',
    iso3: 'SHN',
    number: 654,
  },
  KN: {
    id: 206,
    name: 'Saint Kitts and Nevis',
    currency: 'XCD',
    dialCode: 1869,
    capital: 'Basseterre',
    code: 'KN',
    iso3: 'KNA',
    number: 659,
  },
  LC: {
    id: 204,
    name: 'Saint Lucia',
    currency: 'XCD',
    dialCode: 1758,
    capital: 'Castries',
    code: 'LC',
    iso3: 'LCA',
    number: 662,
  },
  MF: {
    id: 102,
    name: 'Saint Martin (French part)',
    currency: 'EUR',
    dialCode: 590,
    capital: 'Marigot',
    code: 'MF',
    iso3: 'MAF',
    number: 663,
  },
  PM: {
    id: 208,
    name: 'Saint Pierre and Miquelon',
    currency: 'EUR',
    dialCode: 508,
    capital: 'Saint-Pierre',
    code: 'PM',
    iso3: 'SPM',
    number: 666,
  },
  VC: {
    id: 210,
    name: 'Saint Vincent and the Grenadines',
    currency: 'XCD',
    dialCode: 1784,
    capital: 'Kingstown',
    code: 'VC',
    iso3: 'VCT',
    number: 670,
  },
  WS: {
    id: 200,
    name: 'Samoa',
    currency: 'WST',
    dialCode: 684,
    capital: 'Apia',
    code: 'WS',
    iso3: 'WSM',
    number: 882,
  },
  SM: {
    id: 202,
    name: 'San Marino',
    currency: 'EUR',
    dialCode: 378,
    capital: 'San Marino',
    code: 'SM',
    iso3: 'SMR',
    number: 674,
  },
  SA: {
    id: 11,
    name: 'Saudi Arabia',
    currency: 'SAR',
    dialCode: 966,
    capital: 'Riyadh',
    code: 'SA',
    iso3: 'SAU',
    number: 682,
  },
  SN: {
    id: 211,
    name: 'Senegal',
    currency: 'XOF',
    dialCode: 221,
    capital: 'Dakar',
    code: 'SN',
    iso3: 'SEN',
    number: 686,
  },
  RS: {
    id: 213,
    name: 'Serbia',
    currency: 'RSD',
    dialCode: 381,
    capital: 'Belgrade',
    code: 'RS',
    iso3: 'SRB',
    number: 688,
  },
  SC: {
    id: 215,
    name: 'Seychelles',
    currency: 'SCR',
    dialCode: 248,
    capital: 'Victoria',
    code: 'SC',
    iso3: 'SYC',
    number: 690,
  },
  SL: {
    id: 212,
    name: 'Sierra Leone',
    currency: 'SLL',
    dialCode: 232,
    capital: 'Freetown',
    code: 'SL',
    iso3: 'SLE',
    number: 694,
  },
  SG: {
    id: 47,
    name: 'Singapore',
    currency: 'SGD',
    dialCode: 65,
    capital: 'Singapur',
    code: 'SG',
    iso3: 'SGP',
    number: 702,
  },
  SX: {
    id: 207,
    name: 'Sint Maarten (Dutch part)',
    currency: 'ANG',
    dialCode: 1,
    capital: 'Philipsburg',
    code: 'SX',
    iso3: 'SXM',
    number: 534,
  },
  SK: {
    id: 66,
    name: 'Slovakia',
    currency: 'EUR',
    dialCode: 421,
    capital: 'Bratislava',
    code: 'SK',
    iso3: 'SVK',
    number: 703,
  },
  SI: {
    id: 67,
    name: 'Slovenia',
    currency: 'EUR',
    dialCode: 386,
    capital: 'Ljubljana',
    code: 'SI',
    iso3: 'SVN',
    number: 705,
  },
  SB: {
    id: 117,
    name: 'Solomon Islands',
    currency: 'SBD',
    dialCode: 677,
    capital: 'Honiara',
    code: 'SB',
    iso3: 'SLB',
    number: 90,
  },
  SO: {
    id: 217,
    name: 'Somalia',
    currency: 'SOS',
    dialCode: 252,
    capital: 'Mogadishu',
    code: 'SO',
    iso3: 'SOM',
    number: 706,
  },
  ZA: {
    id: 2,
    name: 'South Africa',
    currency: 'ZAR',
    dialCode: 27,
    capital: 'Pretoria',
    code: 'ZA',
    iso3: 'ZAF',
    number: 710,
  },
  SS: {
    id: 221,
    name: 'South Sudan',
    currency: 'SSP',
    dialCode: 211,
    capital: 'Juba',
    code: 'SS',
    iso3: 'SSD',
    number: 728,
  },
  ES: {
    id: 68,
    name: 'Spain',
    currency: 'EUR',
    dialCode: 34,
    capital: 'Madrid',
    code: 'ES',
    iso3: 'ESP',
    number: 724,
  },
  LK: {
    id: 218,
    name: 'Sri Lanka',
    currency: 'LKR',
    dialCode: 94,
    capital: 'Colombo',
    code: 'LK',
    iso3: 'LKA',
    number: 144,
  },
  SD: {
    id: 220,
    name: 'Sudan',
    currency: 'SDG',
    dialCode: 249,
    capital: 'Khartoum',
    code: 'SD',
    iso3: 'SDN',
    number: 736,
  },
  SR: {
    id: 224,
    name: 'Suriname',
    currency: 'SRD',
    dialCode: 597,
    capital: 'Paramaribo',
    code: 'SR',
    iso3: 'SUR',
    number: 740,
  },
  SZ: {
    id: 219,
    name: 'Swaziland',
    currency: 'SZL',
    dialCode: 268,
    capital: 'Mbabane',
    code: 'SZ',
    iso3: 'SWZ',
    number: 748,
  },
  SE: {
    id: 222,
    name: 'Sweden',
    currency: 'SEK',
    dialCode: 46,
    capital: 'Stockholm',
    code: 'SE',
    iso3: 'SWE',
    number: 752,
  },
  CH: {
    id: 223,
    name: 'Switzerland',
    currency: 'CHF',
    dialCode: 41,
    capital: 'Berne',
    code: 'CH',
    iso3: 'CHE',
    number: 756,
  },
  SY: {
    id: 216,
    name: 'Syria',
    currency: 'SYP',
    dialCode: 963,
    capital: 'Damascus',
    code: 'SY',
    iso3: 'SYR',
    number: 760,
  },
  ST: {
    id: 209,
    name: 'São Tomé and Príncipe',
    currency: 'STD',
    dialCode: 239,
    capital: 'Sao Tome',
    code: 'ST',
    iso3: 'STP',
    number: 678,
  },
  TW: {
    id: 227,
    name: 'Taiwan',
    currency: 'TWD',
    dialCode: 886,
    capital: 'Taipei',
    code: 'TW',
    iso3: 'TWN',
    number: 158,
  },
  TJ: {
    id: 228,
    name: 'Tajikistan',
    currency: 'TJS',
    dialCode: 992,
    capital: 'Dushanbe',
    code: 'TJ',
    iso3: 'TJK',
    number: 762,
  },
  TZ: {
    id: 229,
    name: 'Tanzania',
    currency: 'TZS',
    dialCode: 255,
    capital: 'Dodoma',
    code: 'TZ',
    iso3: 'TZA',
    number: 834,
  },
  TH: {
    id: 226,
    name: 'Thailand',
    currency: 'THB',
    dialCode: 66,
    capital: 'Bangkok',
    code: 'TH',
    iso3: 'THA',
    number: 764,
  },
  TG: {
    id: 234,
    name: 'Togo',
    currency: 'XOF',
    dialCode: 228,
    capital: 'Lome',
    code: 'TG',
    iso3: 'TGO',
    number: 768,
  },
  TK: {
    id: 236,
    name: 'Tokelau',
    currency: 'NZD',
    dialCode: 690,
    capital: 'Nukunonu',
    code: 'TK',
    iso3: 'TKL',
    number: 772,
  },
  TO: {
    id: 235,
    name: 'Tonga',
    currency: 'TOP',
    dialCode: 676,
    capital: "Nuku'alofa",
    code: 'TO',
    iso3: 'TON',
    number: 776,
  },
  TT: {
    id: 237,
    name: 'Trinidad and Tobago',
    currency: 'TTD',
    dialCode: 1868,
    capital: 'Port of Spain',
    code: 'TT',
    iso3: 'TTO',
    number: 780,
  },
  TN: {
    id: 238,
    name: 'Tunisia',
    currency: 'TND',
    dialCode: 216,
    capital: 'Tunis',
    code: 'TN',
    iso3: 'TUN',
    number: 788,
  },
  TR: {
    id: 240,
    name: 'Turkey',
    currency: 'TRY',
    dialCode: 90,
    capital: 'Ankara',
    code: 'TR',
    iso3: 'TUR',
    number: 792,
  },
  TM: {
    id: 239,
    name: 'Turkmenistan',
    currency: 'TMT',
    dialCode: 7370,
    capital: 'Ashgabat',
    code: 'TM',
    iso3: 'TKM',
    number: 795,
  },
  TC: {
    id: 118,
    name: 'Turks and Caicos Islands',
    currency: 'USD',
    dialCode: 1649,
    capital: 'Cockburn Town',
    code: 'TC',
    iso3: 'TCA',
    number: 796,
  },
  TV: {
    id: 241,
    name: 'Tuvalu',
    currency: 'AUD',
    dialCode: 688,
    capital: 'Funafuti',
    code: 'TV',
    iso3: 'TUV',
    number: 798,
  },
  UG: {
    id: 243,
    name: 'Uganda',
    currency: 'UGX',
    dialCode: 256,
    capital: 'Kampala',
    code: 'UG',
    iso3: 'UGA',
    number: 800,
  },
  UA: {
    id: 242,
    name: 'Ukraine',
    currency: 'UAH',
    dialCode: 380,
    capital: 'Kiev',
    code: 'UA',
    iso3: 'UKR',
    number: 804,
  },
  AE: {
    id: 63,
    name: 'United Arab Emirates',
    currency: 'AED',
    dialCode: 971,
    capital: 'Abu Dhabi',
    code: 'AE',
    iso3: 'ARE',
    number: 784,
  },
  GB: {
    id: 190,
    name: 'United Kingdom',
    currency: 'GBP',
    dialCode: 44,
    capital: 'London',
    code: 'GB',
    iso3: 'GBR',
    number: 826,
  },
  US: {
    id: 69,
    name: 'United States',
    currency: 'USD',
    dialCode: 1,
    capital: 'Washington',
    code: 'US',
    iso3: 'USA',
    number: 840,
    states: [
      {
        label: 'Alabama',
        value: 'AL',
      },
      {
        label: 'Alaska',
        value: 'AK',
      },
      {
        label: 'American Samoa',
        value: 'AS',
      },
      {
        label: 'Arizona',
        value: 'AZ',
      },
      {
        label: 'Arkansas',
        value: 'AR',
      },
      {
        label: 'California',
        value: 'CA',
      },
      {
        label: 'Colorado',
        value: 'CO',
      },
      {
        label: 'Connecticut',
        value: 'CT',
      },
      {
        label: 'Delaware',
        value: 'DE',
      },
      {
        label: 'District Of Columbia',
        value: 'DC',
      },
      {
        label: 'Federated States Of Micronesia',
        value: 'FM',
      },
      {
        label: 'Florida',
        value: 'FL',
      },
      {
        label: 'Georgia',
        value: 'GA',
      },
      {
        label: 'Guam',
        value: 'GU',
      },
      {
        label: 'Hawaii',
        value: 'HI',
      },
      {
        label: 'Idaho',
        value: 'ID',
      },
      {
        label: 'Illinois',
        value: 'IL',
      },
      {
        label: 'Indiana',
        value: 'IN',
      },
      {
        label: 'Iowa',
        value: 'IA',
      },
      {
        label: 'Kansas',
        value: 'KS',
      },
      {
        label: 'Kentucky',
        value: 'KY',
      },
      {
        label: 'Louisiana',
        value: 'LA',
      },
      {
        label: 'Maine',
        value: 'ME',
      },
      {
        label: 'Marshall Islands',
        value: 'MH',
      },
      {
        label: 'Maryland',
        value: 'MD',
      },
      {
        label: 'Massachusetts',
        value: 'MA',
      },
      {
        label: 'Michigan',
        value: 'MI',
      },
      {
        label: 'Minnesota',
        value: 'MN',
      },
      {
        label: 'Mississippi',
        value: 'MS',
      },
      {
        label: 'Missouri',
        value: 'MO',
      },
      {
        label: 'Montana',
        value: 'MT',
      },
      {
        label: 'Nebraska',
        value: 'NE',
      },
      {
        label: 'Nevada',
        value: 'NV',
      },
      {
        label: 'New Hampshire',
        value: 'NH',
      },
      {
        label: 'New Jersey',
        value: 'NJ',
      },
      {
        label: 'New Mexico',
        value: 'NM',
      },
      {
        label: 'New York',
        value: 'NY',
      },
      {
        label: 'North Carolina',
        value: 'NC',
      },
      {
        label: 'North Dakota',
        value: 'ND',
      },
      {
        label: 'Northern Mariana Islands',
        value: 'MP',
      },
      {
        label: 'Ohio',
        value: 'OH',
      },
      {
        label: 'Oklahoma',
        value: 'OK',
      },
      {
        label: 'Oregon',
        value: 'OR',
      },
      {
        label: 'Palau',
        value: 'PW',
      },
      {
        label: 'Pennsylvania',
        value: 'PA',
      },
      {
        label: 'Puerto Rico',
        value: 'PR',
      },
      {
        label: 'Rhode Island',
        value: 'RI',
      },
      {
        label: 'South Carolina',
        value: 'SC',
      },
      {
        label: 'South Dakota',
        value: 'SD',
      },
      {
        label: 'Tennessee',
        value: 'TN',
      },
      {
        label: 'Texas',
        value: 'TX',
      },
      {
        label: 'Utah',
        value: 'UT',
      },
      {
        label: 'Vermont',
        value: 'VT',
      },
      {
        label: 'Virgin Islands',
        value: 'VI',
      },
      {
        label: 'Virginia',
        value: 'VA',
      },
      {
        label: 'Washington',
        value: 'WA',
      },
      {
        label: 'West Virginia',
        value: 'WV',
      },
      {
        label: 'Wisconsin',
        value: 'WI',
      },
      {
        label: 'Wyoming',
        value: 'WY',
      },
    ],
  },
  UY: {
    id: 244,
    name: 'Uruguay',
    currency: 'UYU',
    dialCode: 598,
    capital: 'Montevideo',
    code: 'UY',
    iso3: 'URY',
    number: 858,
  },
  UZ: {
    id: 245,
    name: 'Uzbekistan',
    currency: 'UZS',
    dialCode: 998,
    capital: 'Tashkent',
    code: 'UZ',
    iso3: 'UZB',
    number: 860,
  },
  VU: {
    id: 246,
    name: 'Vanuatu',
    currency: 'VUV',
    dialCode: 678,
    capital: 'Port Vila',
    code: 'VU',
    iso3: 'VUT',
    number: 548,
  },
  VA: {
    id: 247,
    name: 'Vatican City',
    currency: 'EUR',
    dialCode: 39,
    capital: 'Vaticano',
    code: 'VA',
    iso3: 'VAT',
    number: 336,
  },
  VN: {
    id: 249,
    name: 'Vietnam',
    currency: 'VND',
    dialCode: 84,
    capital: 'Hanoi',
    code: 'VN',
    iso3: 'VNM',
    number: 704,
  },
  VG: {
    id: 120,
    name: 'Virgin Islands, British',
    currency: 'USD',
    dialCode: 1284,
    capital: 'Road Town',
    code: 'VG',
    iso3: 'VGB',
    number: 92,
  },
  VI: {
    id: 119,
    name: 'Virgin Islands, U.S.',
    currency: 'USD',
    dialCode: 1340,
    capital: 'Charlotte Amalie',
    code: 'VI',
    iso3: 'VIR',
    number: 850,
  },
  WF: {
    id: 250,
    name: 'Wallis and Futuna',
    currency: 'XPF',
    dialCode: 681,
    capital: 'Mata Utu',
    code: 'WF',
    iso3: 'WLF',
    number: 876,
  },
  EH: {
    id: 198,
    name: 'Western Sahara',
    currency: 'MAD',
    dialCode: 212,
    capital: 'El-Aaiun',
    code: 'EH',
    iso3: 'ESH',
    number: 732,
  },
  YE: {
    id: 98,
    name: 'Yemen',
    currency: 'YER',
    dialCode: 967,
    capital: 'Sanaa',
    code: 'YE',
    iso3: 'YEM',
    number: 887,
  },
  ZM: {
    id: 251,
    name: 'Zambia',
    currency: 'ZMW',
    dialCode: 260,
    capital: 'Lusaka',
    code: 'ZM',
    iso3: 'ZMB',
    number: 894,
  },
  ZW: {
    id: 252,
    name: 'Zimbabwe',
    currency: 'ZWL',
    dialCode: 263,
    capital: 'Harare',
    code: 'ZW',
    iso3: 'ZWE',
    number: 716,
  },
};

export const alpha3toAlpha2 = reduce(
  countries,
  (obj, { iso3, code }) => {
    return { ...obj, [iso3]: code };
  },
  {} as Record<string, CountryCode>,
);

export const getCountryDetails = (countryCode: string | CountryCode): InputPhoneCountry | undefined => {
  return countries[countryCode];
};

export const filterCountryListByCode = (countryCodes: CountryCode[]): Record<string, InputPhoneCountry> => {
  const filteredCountryList: Record<string, InputPhoneCountry> = {};
  countryCodes.forEach((country) => (filteredCountryList[country] = countries[country]));
  return filteredCountryList;
};

export const detectCountryFromPhoneNumber = (phoneNumber: string): PhoneNumber | undefined => {
  if (isEmpty(phoneNumber)) return;

  const asYouType = new AsYouType();
  asYouType.input(phoneNumber);
  return asYouType.getNumber();
};

export const getCountryListSelectOptions = (allowedCountries?: string[]) => {
  const countryKeys = allowedCountries?.length
    ? Object.keys(countries).filter((country) => allowedCountries.includes(country))
    : Object.keys(countries);

  return countryKeys.map((country) => {
    const { name, iso3 } = countries[country];
    return { label: name, value: iso3 };
  });
};

export const getCountryFlagByAlpha3 = (value?: string): string | undefined => {
  if (!value) {
    return;
  }

  return alpha3toAlpha2[value]?.toLowerCase();
};

export const getStateListSelectOptions = (countryCode?: string): { label: string; value: string }[] => {
  if (!countryCode) return [];

  return getCountryDetails(alpha3toAlpha2[countryCode])?.states || [];
};

export const isValidPhoneNumberForCountry = (phoneNumberString: string, country: CountryCode) => {
  const phoneNumber = parsePhoneNumberWithError(phoneNumberString, {
    defaultCountry: country,
    // Demand that the entire input string must be a phone number.
    // Otherwise, it would "extract" a phone number from an input string.
    extract: false,
  });
  if (!phoneNumber) {
    return false;
  }
  if (phoneNumber.country !== country) {
    return false;
  }
  return phoneNumber.isValid();
};
