import { Injectable } from '@angular/core';
import { uniq } from 'lodash-es';
import { Observable, map } from 'rxjs';

import { ApiService } from '@rocketcoms/data-access';
import { FilterList, FilterListDetailsResponse, FilterListsResponse } from '@rocketcoms/interfaces';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor(private apiService: ApiService) {}

  // API: Get Filter Lists
  public getFilters(): Observable<FilterList[]> {
    return this.apiService.get<FilterListsResponse>(`/admin/filter-words`).pipe(
      map((response) =>
        response.result.map((filter) => ({
          ...filter,
          keywords: filter.words.join(', '),
          businessAccountNames: filter.businessAccounts?.map((businessAccount) => businessAccount.name).join(', '),
          businessAccountCounter: uniq(filter.businessAccounts?.map((businessAccount) => businessAccount.id)).length || 0,
        })),
      ),
    );
  }

  public getFilterListById(id: string): Observable<FilterList> {
    return this.apiService.get<FilterListDetailsResponse>(`/admin/filter-words/${id}`).pipe(
      map((response) => ({
        ...response.result,
        keywords: response.result.words.join(', '),
        businessAccountNames: response.result.businessAccounts?.map((businessAccount) => businessAccount.name).join(', '),
        businessAccountCounter: uniq(response.result.businessAccounts?.map((businessAccount) => businessAccount.id)).length || 0,
      })),
    );
  }

  public deleteFilterListById(id: string): Observable<void> {
    return this.apiService.delete<void>(`/admin/filter-words/${id}`);
  }

  public addFilterList(data: any): Observable<any> {
    return this.apiService.post<any>(`/admin/filter-words`, data);
  }

  public updateFilterList(filterListId: string, data: any): Observable<any> {
    return this.apiService.put<any>(`/admin/filter-words/${filterListId}`, data);
  }

  public updateBusinessAccountFilterList(campaignId: string, ids: string[]): Observable<any> {
    return this.apiService.put<any>(`/admin/accounts/${campaignId}/filter-words`, { ids });
  }

  public toggleBusinessAccountLookupNumber(campaignId: string, value: boolean): Observable<any> {
    return this.apiService.patch<any>(`/campaigns/${campaignId}`, { lookupNumber: value });
  }

  public toggleBusinessAccountVolumeManagement(campaignId: string, value: boolean): Observable<any> {
    return this.apiService.patch<any>(`/campaigns/${campaignId}`, { volumeManagement: value });
  }

  // public getCampaignFilterLists(campaignId: string): Observable<any> {
  //   return this.apiService.get<any>(`/campaigns/${campaignId}/filter-words`);
  // }
}
