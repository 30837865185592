import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';

import { AuthActions, fromAuth } from '../../../';
import { MainNavComponent } from '../main-nav/main-nav.component';

@Component({
  selector: 'app-main-layout',
  templateUrl: 'main-layout.component.html',
  styleUrls: ['main-layout.component.scss'],
  standalone: true,
  imports: [NgIf, MainNavComponent, AsyncPipe, RouterOutlet],
})
export class MainLayoutComponent {
  isMenuExpanded$ = this.store.select(fromAuth.selectMenuExpanded);

  constructor(private store: Store) {}

  onExpandNavBtnClick() {
    this.store.dispatch(AuthActions.menuExpand());
    setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
    setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
  }

  onCollapseNavBtnClick() {
    this.store.dispatch(AuthActions.menuCollapse());
    setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
    setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
  }
}
